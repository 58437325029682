import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavModule, WavesModule, AccordionModule } from 'ng-uikit-pro-standard';
import { AuthenticationService } from '../_services';
import { User, Role } from '../_models';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  //styleUrls: ['./nav-menu.component.scss']
})


export class NavMenuComponent  {
 // @ViewChild('sidenav', { static: true }) sidenav: SidenavComponent
  currentUser: User;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.user.subscribe(x => this.currentUser = x);    
  }
  ping() {
    this.authenticationService.ping();
  }
  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }

  logout() {
    this.authenticationService.logout();
    //this.router.navigate(['/login']);
  }
}
