import { Component, HostListener, ViewChild, OnInit, Input, ElementRef, EventEmitter  } from '@angular/core';
import { first } from 'rxjs/operators';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray, ValidationErrors, AbstractControl,NgModel,NgControl } from '@angular/forms';
import { Shipping, Package, ItemQty, Box, Address, SimpleLabel } from '../Class';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { delay, switchMap, map, tap } from 'rxjs/operators';
import { Time } from '@angular/common';
import { Router } from '@angular/router';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { ShippingService } from '@app/_services';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})

export class TestComponent {
  locations: string[] = ["AWISEC", "AWICAS"]
  carriers: string[] = ["Endicia", "FedEx"]
  endishippingopt: string[] = ["First", "Priority"]
  fedshippingopt: string[] = ["FEDEX_2_DAY", "FEDEX_GROUND", "SMART_POST"]
  address: Address = new Address;
  itemlist: ItemList[];
  MaxOrderNo: number;
  index: number;
  shippingForm;
  isTest: boolean = true;

  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private shippingService: ShippingService) {

   
    this.shippingForm = fb.group({
      orderno: 0,
      parentorderno: Number,
      location: '',
      items: this.fb.array([
        this.additem()
      ]),

      shipTo: this.fb.array([
        this.addaddress()
      ]),

      labels: this.fb.array([
        this.addlabel()
      ]),
    })

    this.shippingService.getMaxOrderNo()
      .subscribe(data => {
        console.log(data);
        this.MaxOrderNo = data as number + 1;
        console.log(this.MaxOrderNo);
        this.shippingForm.get('orderno').setValue(this.MaxOrderNo);
      });
  }

  loadinfo(formdata): void {
    console.log(this.shippingForm.value);

    this.shippingService.getItemList(this.shippingForm.value)
      .subscribe(data => {
        this.itemlist = data as ItemList[];
        console.log(this.itemlist);
      });

    this.shippingService.orderDetail(this.shippingForm.get('orderno').value)
      .subscribe(data => {

        if (data == null) { // Manual order

        } else { // Reorder
          console.log(data);
          this.shippingForm.get('parentorderno').setValue(data.orderNo);
          this.shippingForm.get('orderno').setValue(data.orderNo + 30000000);
          this.shippingForm.get('location').setValue(data.location);
          this.shippingForm.get('shipTo').setValue(data.shipTo);

          for (let i = 0; i < data.items.length; i++) {
            this.items[i].sku = data.items[i].sku;
            let n = this.itemlist.findIndex(j => j.sku == this.items[i].sku);
            if (n > -1) {
              this.items[i].desc = this.itemlist[n].desc;
            }
            console.log(n);

            this.items[i].qty = data.items[i].qty;
            this.add();
          }

          this.delete(this.items.length - 1);
          console.log(data.location);
          console.log(this.shippingForm.value);
        }
      });
  }

  get shipTo(): FormArray {
    return this.shippingForm.get('shipTo') as FormArray;
  }

  addaddress(): FormGroup {
    return this.fb.group({
      name: [''],
      company: [''],
      email: [''],
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip: [''],
      country: 'US',
      phone: [''],
    })
  }

  addlabel(): FormGroup {
    return this.fb.group({
      IsSignature: [Boolean],
      IsCertified: [Boolean],
      Carrier: String,
      ShippingOption: String
    })
  }

  get items():Array<Item> {
    return this.shippingForm.get('items').value as Array<Item>;
  }

  add(): void {
    (<FormArray>this.shippingForm.get('items')).push(this.additem());
  }

  additem(): FormGroup {
    return this.fb.group({
      sku: ['', Validators.required],
      desc: [''],
      qty: [Number],
      fulfillableQty: [Number]
    })
  }

  delete(index): void {
    this.items.splice(index, 1);
    console.log(this.items);
  }

  selectSku(index: number) {
    this.index = this.itemlist.findIndex(i => i.sku == this.items[this.items.length - 1].sku);
   
    this.items[this.items.length - 1].desc = this.itemlist[this.index].desc;
    this.shippingForm.setControl('items', this.setDesc(this.items));
    console.log(this.items);
  }

  setDesc(item: Item[]): FormArray {
    const formArray = new FormArray([]);
    item.forEach(i => {
      formArray.push(this.fb.group({
        sku: i.sku,
        desc: i.desc,
        qty: i.qty,
        fulfillableQty: i.fulfillableQty
      }));
    });
    return formArray;
  }

  selectDesc() {
    this.index = this.itemlist.findIndex(i => i.desc == this.items[this.items.length - 1].desc);
    this.items[this.items.length - 1].sku = this.itemlist[this.index].sku;
    this.shippingForm.setControl('items', this.setDesc(this.items));
    console.log(this.items);
  }

  public statelist = [
    { name: "Alabama" }, { name: 'Alaska' }, { name: 'Arizona' },
    { name: 'Arkansas' }, { name: 'California' }, { name: 'Colorado' },
    { name: 'Connecticut' }, { name: 'Delaware' }, { name: 'Florida' },
    { name: 'Georgia' }, { name: 'Hawaii' }, { name: 'Idaho' },
    { name: 'Illinois' }, { name: 'Indiana' }, { name: 'Iowa' },
    { name: 'Kansas' }, { name: 'Kentucky' }, { name: 'Louisiana' },
    { name: 'Maine' }, { name: 'Maryland' }, { name: 'Massachusetts' },
    { name: 'Michigan' }, { name: 'Minnesota' }, { name: 'Mississippi' },
    { name: 'Missouri' }, { name: 'Montana' }, { name: 'Nebraska' },
    { name: 'Nevada' }, { name: 'New Hampshire' }, { name: 'New Jersey' },
    { name: 'New Mexico' }, { name: 'New York' }, { name: 'North Carolina' },
    { name: 'North Dakota' }, { name: 'Ohio' }, { name: 'Oklahoma' },
    { name: 'Oregon' }, { name: 'Pennsylvania' }, { name: 'Rhode Island' },
    { name: 'South Carolina' }, { name: 'South Dakota' }, { name: 'Tennessee' },
    { name: 'Texas' }, { name: 'Utah' }, { name: 'Vermont' },
    { name: 'Virginia' }, { name: 'Washington' }, { name: 'AWest Virginia' },
    { name: 'Wisconsin' }, { name: 'Wyoming' }
  ]

  submit(formvalue: object) {
    console.log(formvalue);
  }
}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}

class Item {
  sku: string;
  desc: string;
  qty: number;
  fulfillableQty: number;
}
