
<div class="container">
  <h1 align="center">Smart Return</h1>
  <div class="row">
    <div class="col"></div>
    <div class="col">
      <div *ngIf="!shipping && !receiving">
        <div class="form-group">
          <h5>RMATicket Available</h5>
          <label>Location</label>
          <select class="browser-default custom-select" [(ngModel)]="location">
            <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
          </select>
          <label>RMA# or Received #</label>
          <input mdbInput class="form-control" type="text" (keyup.enter)="onBarcodeChange($event.target.value)" placeholder="Scan....." #input3 />
        </div>
        <hr />
       
      </div>
    </div>
    <div class="col"></div>
  </div>

  <div *ngIf="shipping">
    <div class="row">
      <h5 style="padding-left: 15px;">OrderNo: {{shipping.originalNo}}, CustomerNo:{{shipping.originalCustomerNo}}</h5>
    </div>

    <h5>
      <b>ORIGINAL ORDER INFO</b>
    </h5>

    <div class="row">
      <div class="col-lg-12">
        <table class="table table-bordered table-condensed">
          <thead>
            <tr>
              <th></th>
              <th>SKU</th>
              <th>DESCRIPTION</th>
              <th>ORIGINAL ORDER QUANTITY</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of shipping.oriItems; let i = index">
              <td align="center">{{i+1}}</td>
              <td>{{item.sku}}</td>
              <td>{{item.desc}}</td>
              <td>{{item.qty}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h5>
      <b>ITEMS TO BE RECEIVED</b>
    </h5>

    <div class="row" style="padding-left: 14px;">

      <div style=" padding-top: 7px;">
        Tracking #
      </div>
      <div style="padding-left: 15px;">
        <input mdbInput type="text" [(ngModel)]="trackingNo" placeholder="Please input tracking #" />
      </div>
    </div>
    <div class="row" style="padding-top:25px;">
      <div class="col-lg-12">
        <button style="float: right; margin-bottom: 11px;" (click)="addRow()">Add Row</button>
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th></th>
              <th style="text-align:center">SKU</th>
              <th style="text-align:center">DESCRIPTION</th>
              <th style="width:2%;text-align:center">QTY</th>
              <th style="width:18%;text-align:center">RETURN CODE</th>
              <th style="width:15%;text-align:center">NOTES</th>
              <th style="width:15%;text-align:center">EXPIRATION DATE</th>
              <th style="width:7%;text-align:center">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of shipping.items; let i = index">
              <td align="center">{{i+1}}</td>
              <td>
                <input type="text" list="skulist" [(ngModel)]="item.sku" [ngModelOptions]="{standalone: true}" (change)="selectSku(i)" />
                <datalist id="skulist">
                  <select>
                    <option *ngFor="let item of itemList" [value]="item.sku"></option>
                  </select>
                </datalist>
              </td>
              <td>
                <input type="text" list="desclist" [(ngModel)]="item.desc" [ngModelOptions]="{standalone: true}" (change)="selectDesc(i)" />
                <datalist id="desclist">
                  <select>
                    <option *ngFor="let item of itemlist" [value]="item.desc"></option>
                  </select>
                </datalist>
              </td>
              <td><input type="number" [(ngModel)]="item.qty" /></td>
              <td>
                <select [(ngModel)]="item.grade">
                  <option *ngFor="let opt of retruncodes" [ngValue]="opt">{{opt}}</option>
                </select>
              </td>
              <td><input type="text" [(ngModel)]="item.note" /></td>
              <td>
                <mdb-date-picker [inline]="true" [options]="myDatePickerOptions" [placeholder]="'Expiration Date'" [(ngModel)]="item.expirationDate" required></mdb-date-picker>
              </td>
              <td>
                <button (click)="deleteRow(i)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <span>
          <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="onExit()" mdbWavesEffect>Exit</button>
        </span>
        <span>
          <button mdbBtn class="btn btn-default btn-sm" type="button" color="mdb-color" (click)="onCompletedwithRMA()" mdbWavesEffect>Completed</button>
        </span>
      </div>
    </div>

  </div>




  </div>


