import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { ShippingService } from '@app/_services';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-reorder-list',
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss']
})

export class ReorderListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ReOrderList.xlsx');
  }

  currentUser:User;
  elements: any = [];
  
  total: number;

  mastercheck: boolean = false;
  checkedlist: string[] = [];
  isLoading: boolean = false;

  mainForm;
  memoForm;

  locations: string[] = ["All", "AWISEC", "AWICAS"];
  locationOptionsSelect: object[] = [
    { value: 'All', label: 'All' },
    { value: 'AWICAS', label: 'AWICAS' },
    { value: 'AWISEC', label: 'AWISEC' },
  ];

  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  constructor(
    //public datepipe: DatePipe,
    private fb: FormBuilder, private http: HttpClient,
    private shippingService: ShippingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private authenticationService: AuthenticationService) { }

  createCheckedList(value: any, id: string) {

    if (value.currentTarget.checked == true) {
      this.checkedlist.push(id);
    }
    if (value.currentTarget.checked == false) {
      const index: number = this.checkedlist.indexOf(id);
      if (index !== -1) {
        this.checkedlist.splice(index, 1);
      }
    }
    console.log(this.checkedlist);
  }

  masterCheck() {
    this.mastercheck = !this.mastercheck;
    this.checkedlist = [];
    if (this.mastercheck == true) {
      for (let i = 0; i < this.elements.length; i++) {
        this.elements[i].checked = this.mastercheck;
        this.checkedlist.push(this.elements[i].id);
      }
    }

    else if (this.mastercheck == false) {
      for (let i = 0; i < this.elements.length; i++) {
        this.elements[i].checked = this.mastercheck;
      }
    }
    console.log(this.checkedlist);
  }

 


  ngOnInit() {

    this.currentUser = this.authenticationService.userValue;
    var startAt = new Date(new Date().setHours(0, 0, 0, 0))
    startAt.setMonth(startAt.getMonth() - 1);

    this.activatedRoute.paramMap.subscribe(params => {
      this.mainForm = this.fb.group({
        customer: this.currentUser.customer,
        location: params.get('location') ? params.get('location') : 'All',
        startAt: startAt,
        endAt: new Date(new Date().setHours(0, 0, 0, 0)),
        page: 1
      });
      this.onSubmit();
    })
   

    this.memoInit();

    //this.location = this.mainForm.controls['location'];
  }

  memoInit() {
    this.memoForm = this.fb.group({
      customer: this.currentUser.customer,
      orderNo: '',
      content: ''
    });
  }



  holdShipping() {
  //  console.log(this.checkedlist);
    this.shippingService.holdMultipleOrders(this.checkedlist).subscribe(data => {
      alert('hold done.');
      this.onSubmit();
    })
    
  }

  onSubmit() {
    this.isLoading = true;
    console.log(this.mainForm.value);
    this.shippingService.getReorderList(this.mainForm.value).subscribe(data => {
      this.isLoading = false;
      this.elements = data;
    })
  }

  onMemoSubmit(customerData) {
    console.warn(customerData);

    if (this.memoForm.valid) {
      this.shippingService.memo(this.memoForm.value).subscribe(data => {
        //console.log(data);

        if (data["isSuccess"]) {
          alert('memo inserted.');
          this.memoInit();
          this.onSubmit();
        }
        else {
          alert(data["errorMsg"]);
        }
      });
    }
    else {
      alert(this.memoForm.status);
    }

    //this.checkoutForm.reset();
  }

  //loading = false;
  //currentUser: User;
  //userFromApi: User;

  //constructor(
  //    private userService: UserService,
  //    private authenticationService: AuthenticationService
  //) {
  //  this.currentUser = this.authenticationService.currentUserValue;
  //  console.log(this.currentUser);
  //}

  //ngOnInit() {
  //    this.loading = true;
  //    this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
  //        this.loading = false;
  //        this.userFromApi = user;
  //    });
  //}
}
