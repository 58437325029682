import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    if (window) {
      window.console.log = function () { };
      
    }
}
if (window) {
  if (window.location.host == 'b2c2.awi-usa.com') {
    if (window.location.protocol == 'http:') {
      window.location.href = `https://b2c2.awi-usa.com${location.pathname}`;
    }

  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
