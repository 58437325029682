
<h3>Rest Orders</h3>
<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>

<form [formGroup]="mainForm" (ngSubmit)="onSubmit()">
  <table>
    <tr>
      <td>
        <select formControlName="location" class="browser-default custom-select" style="width: 100px;font-size: small;">
          <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
        </select>
      </td>
      <td>
        <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>
          <mdb-icon fas icon="search"></mdb-icon>
        </button>
      </td>
    </tr>
  </table>
</form>


<form [formGroup]="memoForm" (ngSubmit)="onMemoSubmit(memoForm.value)" class="row float-right">
  <table>
    <tr>
      <td>
        <input type="text" class="form-control" formControlName="orderNo" placeholder="Order #">
      </td>
      <td>
        <input type="text" class="form-control" formControlName="content" placeholder="Memo">
      </td>
      <td>
        <button mdbBtn class="btn btn-ins" rounded="true" size="sm" type="submit" mdbWavesEffect>Submit</button>
      </td>
      <td>
        <button mdbBtn type="button" color="pin" rounded="true" (click)="holdShipping()" size="sm" mdbWavesEffect>Hold Selected Orders</button>
        <button mdbBtn type="button" color="slack" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
      </td>
    </tr>
  </table>
</form>

<div>
  Total Orders: {{total}}
</div>

<div #TABLE #table>
  <table class="table table-bordered table-sm">
    <thead>
      <tr align="center">
        <th><input type="checkbox" [(ngModel)]="mastercheck" (click)="masterCheck()" /></th>
        <th scope="col">Customer #</th>
        <th scope="col">Order #</th>
        <th scope="col">Location</th>
        <th scope="col">State</th>
        <th scope="col">On Hold</th>
        <th scope="col">Name / Email</th>
        <th scope="col">Items</th>
        <th scope="col">Qty</th>
        <th scope="col">Shipping Option</th>
        <th scope="col">Risk Level</th>
        <th scope="col">Paid at</th>
        <th scope="col">Log</th>
        <th scope="col">Memo</th>
      </tr>
    </thead>
    <tbody *ngFor="let el of elements; let i = index">
      <tr mdbTableCol *ngFor="let item of el.items; let j = index">
        <td style="text-align:center;" *ngIf="j==0">
          <input type="checkbox" [checked]="el.checked" value="el.id" (change)="createCheckedList($event, el.id)" />
        </td>
        <td *ngIf="j>0" style="color:white;border-top:hidden"></td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.customerNo}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.customerNo}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
          <a [routerLink]="['/orderdetail',el.orderNo]">{{el.orderNo}}</a>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">
          {{el.orderNo}}
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:1%;" *ngIf="j==0">{{el.location}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="width:1%; color:transparent;border-top:hidden">{{el.location}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0"> <span *ngIf="el.shipTo">{{el.shipTo.state}}</span></td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden"> <span *ngIf="el.shipTo">{{el.shipTo.state}}</span></td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:1%;" *ngIf="j==0">
          <span *ngIf="el.isHold" class="red-text">{{el.isHold ? "Yes" : "No"}}</span>
          <span *ngIf="!el.isHold">{{el.isHold ? "Yes" : "No"}}</span>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="width:1%; color:transparent;border-top:hidden">
          <span *ngIf="el.isHold">{{el.isHold}}</span>
          <span *ngIf="!el.isHold">{{el.isHold}}</span>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:18%;" *ngIf="j==0">
          <span *ngIf="el.shipTo">{{el.shipTo.name}}</span> /
          <span *ngIf="el.shipTo">{{el.shipTo.email}}</span>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:18%;" *ngIf="j>0" style="color:transparent;border-top:hidden">
          <span *ngIf="el.shipTo">{{el.shipTo.name}}</span> /
          <span *ngIf="el.shipTo">{{el.shipTo.email}}</span>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'">
          {{item.sku}}
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'">
          {{item.qty}}
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.shippingOption}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.shippingOption}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.riskLevel}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.riskLevel}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.paidAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.paidAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:16%;" *ngIf="j==0">
          <div *ngFor="let log of el.logs;">
            {{log.content}} ({{log.createdAt | date:'MM/dd/yyyy HH:mm'}})
          </div>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:16%;" *ngIf="j>0" style="color:transparent;border-top:hidden">
          <div *ngFor="let log of el.logs;">
            {{log.content}} ({{log.createdAt | date:'MM/dd/yyyy HH:mm'}})
          </div>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
          <div *ngFor="let memo of el.memoes;" style="text-wrap:normal;">
            {{memo.content}}({{memo.createdAt | date:'MM/dd/yyyy HH:mm'}})
          </div>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">
          <div *ngFor="let memo of el.memoes;" style="text-wrap:normal;">
            {{memo.content}}({{memo.createdAt | date:'MM/dd/yyyy HH:mm'}})
          </div>
        </td>
      </tr>
    </tbody>
  </table>

</div>


