<h1>Disposal List</h1>

<div class="container-fluid">


      <p align="right">
         <!--<button mdbBtn type="button" color="amber" rounded="true" (click)="gotoRRCreation()" class="btn btn-success" size="sm" mdbWavesEffect>Create Return Receiving</button>-->
        <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
      </p>

</div>


<table class="table table-bordered table-sm" style="margin-top: 10px;" #TABLE #table>
  <thead>
    <tr align="center">
      <th></th>
      <th>Location</th>
      <th>Received #</th>
      <th>RMA #</th>
      <th>Original Order #</th>
      <th>Original Customer #</th>
      <th>Status</th>
      <th>Items</th>
      <th>Receipt qty</th>
      <th>Return Code</th>
      <th>Note</th>
     
      <th>Created by</th>
      <th>Created At</th>
      <th>Completed At</th>
      <th>Reassigned To Attention At</th>
      <th>Memos</th>
      <th>Logs</th>
     
    </tr>
  </thead>
  <tbody *ngFor="let el of elements ; let i = index">
    <tr mdbTableCol *ngFor="let item of el.items; let j = index">
      <th *ngIf="j==0">{{i+1}}</th>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{i+1}}</th>
      <th *ngIf="j==0">{{el.location}}</th>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{el.location}}</th>
      <td *ngIf="j==0">{{el.receivedNo}}</td>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{el.receivedNo}}</th>
      <td *ngIf="j==0">{{el.rmaNo}}</td>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{el.rmaNo}}</th>
      <td *ngIf="j==0">{{el.originalNo}}</td>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{el.originalNo}}</th>
      <td *ngIf="j==0">{{el.originalCustomerNo}}</td>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{el.originalCustomerNo}}</th>
      <th>{{item.statusSign}}</th>
      <th>{{item.sku}}</th>
      <th>{{item.receiptQty}}</th>
      <th>{{item.grade}}</th>
      <th>{{item.note}}</th>


      <td *ngIf="j==0">{{el.createdBy}}</td>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{el.createdBy}}></th>
      <td *ngIf="j==0">{{el.createdAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{el.createdAt | date:'MM/dd/yyyy HH:mm'}}</th>
      <td *ngIf="j==0">{{el.completedAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{el.completedAt | date:'MM/dd/yyyy HH:mm'}}</th>
      <td *ngIf="j==0">{{el.reassignedAttentionAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <th *ngIf="j>0" style="color:white;border-top:hidden">{{el.reassignedAttentionAt | date:'MM/dd/yyyy HH:mm'}}</th>
      <td *ngIf="j==0">
        <div *ngFor="let memo of el.memoes;">
          {{memo.content}} 
        </div>
      </td>
      <th *ngIf="j>0" style="color:white;border-top:hidden"></th>
      <td *ngIf="j==0">
        <div *ngFor="let log of el.logs;">
          {{log.content}} 
        </div>
      </td>
      <th *ngIf="j>0" style="color:white;border-top:hidden"></th>

    </tr>

  </tbody>
  
</table>
