import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { ShippingService } from '@app/_services';


@Component({
  selector: 'app-hold-list',
  templateUrl: './hold-list.component.html',
  styleUrls: ['./hold-list.component.scss']
})

export class HoldListComponent {
  elements: any;
  mainForm;

  mastercheck: boolean = false;
  
  filterAddressError: boolean = false;

  constructor(
    private fb: FormBuilder, private http: HttpClient,
    private shippingService: ShippingService,
    private router: Router) { }

  ngOnInit() {
    this.mainForm = this.fb.group({
      location: 'All',
      page: 1
    });

    this.onSubmit();
    
  }

  filterAddressErrors(value: any) {
    if (this.filterAddressError == true) {
      this.elements = this.elements.filter(el =>
        el.hasAddressError == true
      );
    } else {
      this.onSubmit();
    }
  }

  createCheckedList(value: any, id: string) {
    var element = this.elements.filter(x => x.id == id);
    if (element) {
      element.checked = !element.checked;
      console.log(element.checked);
    }
    
  }

  masterCheck() {
    
    this.mastercheck = this.mastercheck == true;

    console.log(this.mastercheck);
    (this.elements).map(x => {
      x.checked = this.mastercheck;
      console.log(x.checked);
    })
  }

  onSubmit() {
    this.shippingService.getHoldList("All").subscribe(data => {
        console.log(data);
        this.elements = data;
        console.log('succeed');
      });

  }
  

  unHold(orderno) {
    console.log(orderno);
    this.shippingService.unHold(orderno)
      .subscribe(data => {
        alert('unhold success');
        this.onSubmit();
      });
  }

  unHoldMultipleOrders() {
    const selectedIdList = (this.elements).map(x => {
      if (x.checked) {
        return x.id;
      }
      return null;
    }).filter(x => x);
    
    if (selectedIdList.length > 0) {
      this.shippingService.unholdMultipleOrders(selectedIdList).subscribe(data => {
        if (data['isSuccess'] == true) {
          alert(`Successfully status changed for ${selectedIdList} order${selectedIdList.length > 1 ? "s" : ""}.`);
        } else {
          alert(data['errorMsg']);
        }
        this.mastercheck = false;
        this.onSubmit();
      });
    }
    else {
      alert(`Couldn't find any selected order. \r\nPlease select more than one order and try again.`);
    }
  }

}
