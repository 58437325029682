import { Component, ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { ShippingService } from '@app/_services';
import { FormBuilder } from '@angular/forms';
import { log } from 'util';
import { IMyOptions } from 'ng-uikit-pro-standard';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';


@Component({
  templateUrl: 'orderstatistics.component.html',
  styleUrls: ['./orderstatistics.component.scss']
})
export class OrderStatisticsComponent {
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'fulfillment_stat.xlsx');
  }

  exportPDF() {
    let DATA1 = this.TABLE.nativeElement;
    let doc = new jsPDF("p", "mm", "a4");
    var thetime = new Date();
    var datetime = `Date printed: ${thetime}`;
    html2canvas(DATA1).then(canvas => {
      var image1 = canvas.toDataURL('image/png', 0.1);
      var imgProps = doc.getImageProperties(image1);
      var width = doc.internal.pageSize.getWidth();
      var height = (imgProps.height * width) / imgProps.width;
      doc.setFontSize(8);
      doc.text(datetime, 5, doc.internal.pageSize.height - 3);
      doc.addImage(image1, 'png', 5, 10, width-10, height);
      doc.save('fulfillment_stat.pdf');
    });

  }


  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  isLoading = false;
  user: User;
  mainForm;
  total: number;
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  statInSEC: any;
  statInCAS: any;
  constructor(
    private userService: UserService, private fb: FormBuilder,
    private router: Router,
    private shippingService: ShippingService,
    private authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.userValue;
  }

  ngOnInit() {
    //this.isLoading = true;
    this.mainForm = this.fb.group({
      location:'All',
      customer: this.user.customer,
      endAt: new Date(),
      startAt: new Date(new Date().setDate(new Date().getDate() - 4)),
    });

    this.avgOneDayTaskHours();
  }

  avgOneDayTaskHours() {
    var startAt = moment(this.mainForm.get('startAt').value).format('YYYYMMDD');
    var endAt = moment(this.mainForm.get('endAt').value).format('YYYYMMDD');
    this.shippingService.getFulfillmentReport(startAt, endAt).subscribe(data => {
      this.statInSEC = data['secStat'];
      this.statInCAS = data['casStat'];
      //this.total = this.statInCAS.length + this.statInSEC.length;
      console.log(data);
      console.log(this.statInSEC);
      console.log(this.statInCAS);
    });
  }

  dateFormater(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  onSubmit() {
    this.avgOneDayTaskHours();
  }
}
