import { Component, ElementRef, ViewChild  } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { ShippingService } from '@app/_services';
import * as XLSX from 'xlsx';  



@Component({
  selector: 'app-fulfilled-list',
  templateUrl: './fulfilled-list.component.html',
  styleUrls: ['./fulfilled-list.component.scss']
})

export class FulfilledListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'FulfilledList.xlsx');
  }

  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  currentUser:User;
  elements: any = [];
  total: number;
  mainForm;
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder, private http: HttpClient,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private shippingService: ShippingService,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.userValue;
    this.activatedRoute.paramMap.subscribe(params => {
      this.mainForm = this.fb.group({
        location: params.get('location') ? params.get('location') : 'All',
        customer: this.currentUser.customer,
        //startAt: new Date(2020, 7, 27),
        //endAt: new Date(2020, 7, 27),
        startAt: new Date(new Date().setHours(0, 0, 0, 0)),
        endAt: new Date(new Date().setHours(0, 0, 0, 0)),
        page: 1
      });
      this.onSubmit();
    });
   
  }

  onSubmit() {
    this.isLoading = true;
    this.shippingService.getFulfilledList(this.mainForm.value)
      .subscribe(data => {
        console.log(data);
        this.isLoading = false;
        this.elements = data;
        this.total = this.elements.length;
        console.log('succeed');
      });
  }

  logFilter(logs: any[]) {
    const index = logs.findIndex(x => x.content.includes("Shipping Label was printed by"));
    if (index > -1) {
      logs.splice(index, 1);
    }
    return logs;
  }

  lastTrackingInfo(tracks: any[]): string {
    if (tracks) {
      var length = tracks.length;
      if (length > 0) {
        return tracks[length - 1].statusDesc;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  countingEnRouteDays(tracks: any[]): number {

    if (tracks) {
      if (tracks.some(x => x.statusDesc == "En Route") && !tracks.some(x => x.statusDesc == "Delivered")) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var enRoute = tracks.find(x => x.statusDesc == "En Route");
        var activityOn_temp = new Date(enRoute.activityOn).toUTCString();
        var activityOn = new Date(Date.parse(activityOn_temp.substring(0, 16)));
        var result = activityOn.getTime() - today.getTime(); // skip the first day
        return Math.ceil(Math.abs(result) / (1000 * 60 * 60 * 24));

      } else if (tracks.some(x => x.statusDesc == "En Route") && tracks.some(x => x.statusDesc == "Delivered")) {
        var enRoute = tracks.find(x => x.statusDesc == "En Route");
        var activityOn_temp = new Date(enRoute.activityOn).toUTCString();
        var activityOn = new Date(Date.parse(activityOn_temp.substring(0, 16)));
        var deliveredEvent = tracks.find(x => x.statusDesc == "Delivered");
        var deliveredAt_temp = new Date(deliveredEvent.activityOn).toUTCString();
        var deliveredAt = new Date(Date.parse(deliveredAt_temp.substring(0, 16)));
        var result = activityOn.getTime() - deliveredAt.getTime();
        return Math.ceil(Math.abs(result) / (1000 * 60 * 60 * 24));
      } else {
        return 0;
      }
    } else {
      return 0;
    }

  }
}
