<h1>Closed Returns</h1>
<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" class="float-left">
  <div class="row" style="padding-bottom:5px; padding-right:10px;">
    <div style="padding-left:15px;padding-right:15px;">
      <select formControlName="location" class="browser-default custom-select" style="width: 100px;font-size: small;">
        <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
    <div style="padding-left:20px;padding-right:10px;">
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'From'" formControlName="startAt" required></mdb-date-picker>
    </div>
    <div>
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'To'" formControlName="endAt" required></mdb-date-picker>
    </div>
    <div style="padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>
  </div>
</form>
<div align="right">
  <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
</div>

<table class="table table-bordered table-sm" style="margin-top: 10px;" #TABLE #table>
  <thead>
    <tr align="center">

      <th>Location</th>
      <th>RMA #</th>
      <th>Customer #</th>
      <th>Status</th>
      <th>Items</th>
      <th>Qty</th>
      <th>Return Code</th>
      <th>Note</th>
      <th>Created by</th>
      <th>Created At</th>
      <th>Completed At</th>
      <th>Memoes</th>
      <th>Logs</th>
    </tr>
  </thead>
  <tbody *ngFor="let el of elements ; let i = index">
    <tr mdbTableCol *ngFor="let item of el.items; let j = index">
      <td *ngIf="j==0 && item.unheldQty > 0">{{el.location}}</td>
      <td *ngIf="j>0 && item.unheldQty > 0" style="color:transparent;border-top:hidden">{{el.location}}</td>
      <td *ngIf="j==0 && item.unheldQty > 0">{{el.receivedNo}}</td>
      <td *ngIf="j>0 && item.unheldQty > 0" style="color:transparent;border-top:hidden">{{el.receivedNo}}</td>

      <td *ngIf="j==0 && item.unheldQty > 0">{{el.originalCustomerNo}}</td>
      <td *ngIf="j>0 && item.unheldQty > 0" style="color:transparent;border-top:hidden">{{el.originalCustomerNo}}</td>
      <td align="center">{{item.statusSign}}</td>
      <td>{{item.sku}}</td>
      <td>{{item.receiptQty}}</td>
      <td>{{item.grade}}</td>
      <td>{{item.note}}</td>
      <td *ngIf="j==0 && item.unheldQty > 0">{{el.createdBy}}</td>
      <td *ngIf="j>0 && item.unheldQty > 0" style="color:transparent;border-top:hidden">{{el.createdBy}}></td>
      <td *ngIf="j==0 && item.unheldQty > 0">{{el.createdAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td *ngIf="j>0 && item.unheldQty > 0" style="color:transparent;border-top:hidden">{{el.createdAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td *ngIf="j==0 && item.unheldQty > 0">{{el.completedAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td *ngIf="j>0 && item.unheldQty > 0" style="color:transparent;border-top:hidden">{{el.completedAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td *ngIf="j==0 && item.unheldQty > 0">
        <div *ngFor="let memo of el.memoes;">
          {{memo.content}} ({{memo.from}} {{memo.createdAt| date:'MM/dd/yyyy HH:mm'}})
        </div>
      </td>
      <td *ngIf="j>0 && item.unheldQty > 0" style="color:transparent;border-top:hidden">
        <div *ngFor="let memo of el.memoes;">
          {{memo.content}} ({{memo.from}} {{memo.createdAt| date:'MM/dd/yyyy HH:mm'}})
        </div>
      </td>
      <td *ngIf="j==0 && item.unheldQty > 0">
        <ul *ngFor="let log of el.logs;">
          <li>{{log.content}} At {{log.createdAt |date:'MM/dd/yyyy HH:mm'}} By {{log.from}} </li>
        </ul>
      </td>
      <td *ngIf="j>0 && item.unheldQty > 0" style="color:transparent;border-top:hidden">
        <ul *ngFor="let log of el.logs;">
          <li>{{log.content}} </li>
        </ul>
      </td>
    </tr>
  </tbody>

</table>
