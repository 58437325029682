<div class="container">
  <h3>Order Fulfillment Average Hours</h3>

  <div *ngIf="isLoading">
    <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
    <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
  </div>

  <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">

    <div class="row" style="padding-bottom:5px; padding-right:10px;">
      <div style="padding-left:20px;padding-right:10px;">
        <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'From'" formControlName="startAt" required></mdb-date-picker>
      </div>
      <div>
        <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'To'" formControlName="endAt" required></mdb-date-picker>
      </div>
      <div style="padding-left:15px;padding-right:15px;">
        <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
      </div>
      
    </div>
    <div class="export-button-container" style="float:right;" *ngIf="statInSEC">
      <button (click)="ExportTOExcel()">Excel</button>
      <button (click)="exportPDF()">PDF</button>
    </div>
  </form>
  

  <div id="TABLE" #TABLE>
    
      <table class="table table-bordered table-sm">
        <thead>
          <tr style="text-align: center; font-weight:bold; background-color: lightgray;">
            <td>Date</td>
            <td>NJ Orders</td>
            <td>NJ Fulfilled Orders</td>
            <td>Hours</td>
            <td>Fulfillment Rate (%)</td>
          </tr>
        </thead>
        <tbody>
          <tr align="center" *ngFor="let el of statInSEC; let i = index">
            <td>{{dateFormater(el.date)}}</td>
            <td>{{el.numberOfRestOrders}}</td>
            <td>{{el.numberOfFulfilledOrders}}</td>
            <td>{{el.turnAroundTime}}</td>
            <td>{{el.fulfilledRate}}</td>
          </tr>
        </tbody>
      </table>
  
      <table class="table table-bordered table-sm">
        <thead>
          <tr style="text-align: center; font-weight:bold; background-color: lightgray;">
            <td>Date</td>
            <td>CA Orders</td>
            <td>CA Fulfilled Orders</td>
            <td>Hours</td>
            <td>Fulfillment Rate (%)</td>
          </tr>
        </thead>
        <tbody>
          <tr align="center" *ngFor="let el of statInCAS; let i = index">
            <td>{{dateFormater(el.date)}}</td>
            <td>{{el.numberOfRestOrders}}</td>
            <td>{{el.numberOfFulfilledOrders}}</td>
            <td>{{el.turnAroundTime}}</td>
            <td>{{el.fulfilledRate}}</td>
          </tr>
        </tbody>
      </table>



  </div>

  </div>
