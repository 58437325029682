import { Component, HostListener, ViewChild, OnInit, Input, ElementRef, EventEmitter  } from '@angular/core';
import { first } from 'rxjs/operators';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray, ValidationErrors, AbstractControl,NgModel,NgControl } from '@angular/forms';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { delay, switchMap, map, tap } from 'rxjs/operators';
import { Time, Location } from '@angular/common';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { ShippingService } from '@app/_services';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Shipping, Label, SimpleLabel } from '../../Class';

@Component({
  selector: 'app-orderdetaionmanu',
  templateUrl: './orderdetailonmanu.component.html',
  styleUrls: ['./orderdetailonmanu.component.scss']
})

export class OrderDetailOnManuComponent {

  mainForm;
  isTest: boolean = true;

  currentUser:User;
  orderdetail: any;

  customer;
  orderNo;
  customerNo;

  isAddressEditable: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient, private activatedRoute: ActivatedRoute, 
    private shippingService: ShippingService, private location : Location,
    private authenticationService: AuthenticationService) {

    this.mainForm = fb.group({
      orderNo: Number,
      customerNo: '',
    });

    this.orderNo = this.mainForm.controls['orderNo'];
    this.customerNo = this.mainForm.controls['customerNo'];
  }


  ngOnInit() {
  }

  onSubmit() {
    //this.location.go(`/orderdetail/${}`)
    //this.activatedRoute.paramMap.subscribe(params => {

    //})
    this.mainForm.get('customerNo').setValue(this.mainForm.get('customerNo').value.trim());
    if (this.mainForm.valid) {
      this.shippingService.postOrderDetail(
        this.mainForm.get('orderNo').value,
        this.mainForm.get('customerNo').value
      ).subscribe(data => {
        console.log(data);
        this.orderdetail = data;
      })
    }
  }


  addressUpdateCancel() {
    this.isAddressEditable = !this.isAddressEditable;
  }

  addressUpdate() {

    this.shippingService.updateAddress(this.orderdetail).subscribe(data => {
      console.log(data);
      alert('successfully updated.');
    });

    this.isAddressEditable = !this.isAddressEditable;
  }

  updateAddressForm(): void {

    if (this.orderdetail.labels != null) {
      if (this.orderdetail.labels[0].trackingNo != null) {
        alert('fail to update the address because the label was already created.');
        return;
      }
    }

    if (this.orderdetail.type == "Spf") {
      alert('fail to update the address because this type is Spf.');
      return;
    }

    this.isAddressEditable = !this.isAddressEditable;
  }

  cancelOrder() {
    if (this.orderdetail.type == 'Spf' || this.orderdetail.type == 'Jet') {
      alert('Cannot cancel: This order type is Spf or Jet.');
    }
    else if (this.orderdetail.labels.length > 0 && this.orderdetail.labels[0].trackingNo != null) {
      alert('Cannot cancel: The label has been created.');
    }
    //else if (this.orderdetail.packingEndAt != null) {
    //  alert('Cannot cancel: The items have been packed.');
    //}
    else {
      this.shippingService.cancelOrder(this.orderdetail).subscribe(data => {
        alert('order canceled.');
      });
    }
  }

  holdShipping() {
    this.shippingService.hold(this.mainForm.get('orderNo').value).subscribe(data => {
      if (data['isSuccess'] == true) {
        alert('hold done.');
      } else {
        alert(data['errorMsg']);
      }
    })
  }

  
  
 

 
}

