<div class="container">

  <h3>Order Detail </h3>

  <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">
    <table class="fixed">

      <tr>
        <td>Order # :</td>
        <td>
          <input type="number"
                 style="width: 94px; border-top-style: hidden;
                    border-right-style: hidden;
                    border-left-style: hidden;
                    border-bottom-style: hidden;
                    background-color: #eee;"
                 [formControl]="orderNo"
                 placeholder="Order #" />
        </td>
      </tr>
      <tr>
        <td>Customer # :</td>
        <td>
          <input type="text"
                 style="width: 94px; border-top-style: hidden;
                    border-right-style: hidden;
                    border-left-style: hidden;
                    border-bottom-style: hidden;
                    background-color: #eee;"
                 [formControl]="customerNo"
                 placeholder="Customer #" />
        </td>
        <td>
          <button class="button" type="submit">Submit</button>
        </td>

      </tr>
    </table>
    </form>
  <div class="row" style="float:right;">


    <button *ngIf="orderdetail && !orderdetail.isHold" mdbBtn type="button" color="indigo" rounded="true" size="sm" data-toggle="modal" data-target="#basicExample" (click)="holdframe.show()" mdbWavesEffect>
      Hold Order
    </button>
    <button *ngIf="orderdetail && orderdetail.isHold" mdbBtn type="button" color="indigo" rounded="true" size="sm" data-toggle="modal" data-target="#basicExample" (click)="unholdframe.show()" mdbWavesEffect>
      Unhold Order
    </button>

    <div mdbModal #holdframe="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-notify modal-warning" role="document">
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header">
            <p class="heading lead">Hold Order</p>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="holdframe.hide()">
              <span aria-hidden="true" class="white-text">&times;</span>
            </button>
          </div>

          <!--Body-->
          <div class="modal-body">
            <div class="text-center">
              <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
              <p>
                Do you really want to hold this order?
              </p>
            </div>
          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">
            <a type="button" mdbBtn color="warning" class="waves-effect" (click)="holdShipping()" (click)="holdframe.hide()" mdbWavesEffect>
              Yes
            </a>
            <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="holdframe.hide()">Cancel</a>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>


    <div mdbModal #unholdframe="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-notify modal-warning" role="document">
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header">
            <p class="heading lead">Unhold Order</p>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="unholdframe.hide()">
              <span aria-hidden="true" class="white-text">&times;</span>
            </button>
          </div>

          <!--Body-->
          <div class="modal-body">
            <div class="text-center">
              <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
              <p>
                Do you really want to unhold this order?
              </p>
            </div>
          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">
            <a type="button" mdbBtn color="warning" class="waves-effect" (click)="unholdShipping()" (click)="unholdframe.hide()" mdbWavesEffect>
              Yes
            </a>
            <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="unholdframe.hide()">Cancel</a>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>

    <button *ngIf="orderdetail" mdbBtn type="button" color="unique" rounded="true" size="sm" data-toggle="modal" data-target="#basicExample" (click)="cancelframe.show()" mdbWavesEffect>
      Cancel Order
    </button>

    <div mdbModal #cancelframe="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-notify modal-warning" role="document">
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header">
            <p class="heading lead">Cancel Order</p>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelframe.hide()">
              <span aria-hidden="true" class="white-text">&times;</span>
            </button>
          </div>

          <!--Body-->
          <div class="modal-body">
            <div class="text-center">
              <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
              <p>
                Do you really want to cancel this order?
              </p>
            </div>
          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">
            <a type="button" mdbBtn color="warning" class="waves-effect" (click)="cancelOrder()" (click)="cancelframe.hide()" mdbWavesEffect>
              Yes
            </a>
            <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="cancelframe.hide()">Cancel</a>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>

    <button mdbBtn type="button" color="amber" rounded="true" size="sm" mdbWavesEffect [hidden]="!isAddressEditable" data-toggle="modal" data-target="#basicExample" (click)="addressframe.show()" mdbWavesEffect>
      Update
    </button>

    <div mdbModal #addressframe="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-notify modal-warning" role="document">
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header">
            <p class="heading lead">Cancel Order</p>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="addressframe.hide()">
              <span aria-hidden="true" class="white-text">&times;</span>
            </button>
          </div>

          <!--Body-->
          <div class="modal-body">
            <div class="text-center">
              <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
              <p>
                It's going to be unheld automatically.<br>
                Do you really want to update the address?
              </p>
            </div>
          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">
            <a type="button" mdbBtn color="warning" class="waves-effect" (click)="addressUpdate()" (click)="addressframe.hide()" mdbWavesEffect>
              Yes
            </a>
            <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="addressUpdateCancel()" (click)="addressframe.hide()">Cancel</a>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>

    <button *ngIf="orderdetail && !isAddressEditable" mdbBtn type="button" color="amber" rounded="true" (click)="updateAddressForm()" size="sm" mdbWavesEffect>Address Update</button>
    <button mdbBtn type="button" color="light-blue" rounded="true" size="sm" mdbWavesEffect *ngIf="orderdetail!=null" (click)="updateItemsForm()">Edit Items</button>
  </div>


    <div *ngIf="!isReorder">
      <table class="table table-sm">
        <tbody *ngIf="orderdetail">
          <tr *ngIf="orderdetail.location">
            <td>Location</td>
            <td>{{orderdetail.location}}</td>
          </tr>
          <tr>
            <td>Customer #</td>
            <td>{{orderdetail.customerNo}}</td>
          </tr>
          <tr>
            <td>Order #</td>
            <td>{{orderdetail.orderNo}}</td>
          </tr>

          <tr>
            <td>Items (qty)</td>
            <td>
              <button *ngIf="isItemEditable" (click)="addItem()">Add</button>
              <button *ngIf="isItemEditable" (click)="updateItems()">Update</button>
              <table style="width:100%;">
                <thead>
                  <tr>
                    <td>Sku</td>
                    <td>Desc</td>
                    <td style="width:20%;">Qty</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of orderdetail.items; let i = index">
                    <td>
                      <span [hidden]="isItemEditable">
                        {{item.sku}}
                      </span>
                      <input type="text" list="skulist" *ngIf="isItemEditable" [(ngModel)]="item.sku" (change)="selectSku(i, item.sku)" />
                      <datalist id="skulist">
                        <select>
                          <option *ngFor="let element of itemlist" [value]="element.sku"></option>
                        </select>
                      </datalist>
                    </td>
                    <td>
                      <span [hidden]="isItemEditable">
                        {{item.desc}}
                      </span>
                      <input type="text" list="desclist" *ngIf="isItemEditable" [(ngModel)]="item.desc" (change)="selectDesc(i,  item.desc)" />
                      <datalist id="desclist">
                        <select>
                          <option *ngFor="let element of itemlist" [value]="element.desc"></option>
                        </select>
                      </datalist>
                    </td>
                    <td>
                      <span [hidden]="isItemEditable">
                        {{item.qty}}
                      </span>
                      <input type="number" size="3" *ngIf="isItemEditable" [(ngModel)]="item.qty" style="width: 50%;" /><button *ngIf="isItemEditable" (click)="deleteItem(i)">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <!--<td>
              <div *ngFor="let item of orderdetail.items;">
                {{item.sku}} - {{item.desc}} ({{item.qty}})
              </div>
            </td>-->
          </tr>
          <tr>
            <td>Weight</td>
            <td>{{orderdetail.weight}} (lb)</td>
          </tr>

          <tr>
            <td>Hold</td>
            <td>{{orderdetail.isHold ? "Yes" : "No"}}</td>
          </tr>

          <tr>
            <td>Tracking #</td>
            <td>
              <span *ngIf="orderdetail.labels" [hidden]="isTrackingEditable">{{orderdetail.labels[0].trackingNo}}</span>
              <input *ngIf="isTrackingEditable" type="text" [(ngModel)]="label.trackingNo">
            </td>
          </tr>

          <tr>
            <td>Name</td>
            <td>
              <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
                {{orderdetail.shipTo.name}}
              </span>
              <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.name">
            </td>

          </tr>
          <tr>
            <td>Company</td>
            <td>
              <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
                {{orderdetail.shipTo.company}}
              </span>
              <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.company">
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
                {{orderdetail.shipTo.email}}
              </span>
              <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.email">
            </td>
          </tr>
          <tr>
            <td>Address 1</td>
            <td>
              <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
                {{orderdetail.shipTo.address1}}
              </span>
              <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.address1">
            </td>
          </tr>
          <tr>
            <td>Address 2</td>
            <td>
              <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
                {{orderdetail.shipTo.address2}}
              </span>
              <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.address2">
            </td>
          </tr>
          <tr>
            <td>City</td>
            <td>
              <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
                {{orderdetail.shipTo.city}}
              </span>
              <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.city">
            </td>
          </tr>
          <tr>
            <td>State</td>
            <td>
              <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
                {{orderdetail.shipTo.state}}
              </span>
              <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.state">
            </td>
          </tr>
          <tr>
            <td>Zip</td>
            <td>
              <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
                {{orderdetail.shipTo.zip}}
              </span>
              <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.zip">
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td>
              <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
                {{orderdetail.shipTo.country}}
              </span>
              <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.country">
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div *ngIf="isReorder">
      <form [formGroup]="shippingForm">
        <table class="fixed">
          <tr>
            <td><h6>Order #:</h6></td>
            <td>
              <input type="number" style="width: 94px;" id="orderno" placeholder="Order #" [formControl]="shippingForm.controls['orderno']" />
              <!--(change)="ordernumberchange()"-->
            </td>
          </tr>
        </table>
        <h6>Item Info</h6>
        <table class="table table-bordered table-sm">
          <thead>
            <tr align="center">
              <th scope="col">#</th>
              <th scope="col" for="sku">Sku *</th>
              <th scope="col" for="desc">Description</th>
              <th scope="col" for="qty">Qty *</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody formArrayName="items" *ngFor="let element of shippingForm.get('items').controls; let i = index">
            <tr [formGroupName]="i">
              <td align="center">
                <span>{{i+1}}</span>
              </td>
              <td>
                <input type="text" list="skulist" formControlName="sku" />
                <datalist id="skulist">
                  <select>
                    <option *ngFor="let item of itemlist" [value]="item.sku"></option>
                  </select>
                </datalist>
              </td>
              <td>
                <input type="text" list="desclist" formControlName="desc" />
                <datalist id="desclist">
                  <select>
                    <option *ngFor="let item of itemlist" [value]="item.desc"></option>
                  </select>
                </datalist>
              </td>
              <td>
                <input type="number" formControlName="qty">
              </td>
              <td>
                <span style="padding-right: 5px;">
                  <button (click)="add()">add</button>
                  <button (click)="delete(i)">delete</button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <h5>Address</h5>
        <table class="table table-bordered table-sm" formGroupName="shipTo">
          <tr>
            <td scope="col">Name *</td>
            <td>
              <input type="text" formControlName="name">
            </td>
            <td scope="col">Company</td>
            <td>
              <input type="text" formControlName="company">
            </td>
          </tr>

          <tr>
            <td scope="col">Email</td>
            <td>
              <input type="text" formControlName="email">
            </td>
            <td scope="col">Phone</td>
            <td>
              <input type="text" formControlName="phone">
            </td>
          </tr>
          <tr>
            <td scope="col">Address1</td>
            <td>
              <input type="text" formControlName="address1">
            </td>
            <td scope="col">Address2</td>
            <td>
              <input type="text" formControlName="address2">
            </td>
          </tr>

          <tr>
            <td scope="col">City</td>
            <td>
              <input type="text" formControlName="city">
            </td>
            <td scope="col">State</td>
            <td>
              <input type="text" list="statelist" formControlName="state">
              <datalist id="statelist">
                <select>
                  <option *ngFor="let state of statelist" [value]="state.name"></option>
                </select>
              </datalist>
            </td>

          </tr>


          <tr>
            <td scope="col">Country</td>
            <td>
              <input type="text" formControlName="country">
            </td>
            <td scope="col">Zip</td>
            <td>
              <input type="text" formControlName="zip">
            </td>
          </tr>
        </table>

        <h5>Shipping Option</h5>
        <table class="fixed" formArrayName="labels" *ngFor="let label of shippingForm.get('labels').controls; let i=index">
          <tr>
            <td class="r">Shipping Location*</td>
            <td>
              <select [formControl]="shippingForm.controls['location']">
                <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
          </tr>
          <tr [formGroupName]="i">
            <td class="r">Carrier</td>
            <td>
              <select formControlName="Carrier">
                <option *ngFor="let opt of carriers" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
          </tr>
          <tr [formGroupName]="i">
            <td class="r">Shipping Option</td>
            <td>
              <select formControlName="ShippingOption">
                <option [hidden]="whichCarrier(i)=='FedEx'" *ngFor="let opt of endishippingopt" [ngValue]="opt">{{opt}}</option>
                <option [hidden]="whichCarrier(i)=='Endicia'" *ngFor="let opt of fedshippingopt" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
          </tr>
          <tr [formGroupName]="i">
            <td class="r">Signature</td>
            <td>
              <select formControlName="IsSignature">
                <option [ngValue]="true">True</option>
                <option [ngValue]="false">False</option>
              </select>
            </td>
          </tr>
          <tr [formGroupName]="i">
            <td class="r">Certified</td>
            <td>
              <select formControlName="IsCertified">
                <option [ngValue]="true">True</option>
                <option [ngValue]="false">False</option>
              </select>
            </td>
          </tr>
        </table>

        <button (click)="submit(shippingForm.value)">Submit</button>

      </form>


    </div>

</div>
