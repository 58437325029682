import { MDBBootstrapModulesPro, MDBSpinningPreloader, DropdownModule } from 'ng-uikit-pro-standard';
import { SidenavModule, WavesModule, AccordionModule, ChartsModule, ChartSimpleModule } from 'ng-uikit-pro-standard';

import { NgModule, APP_INITIALIZER, Pipe, PipeTransform } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DatePipe, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { AuthenticationService } from './_services';

import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './login';
import { InventoryComponent } from './inventory/inventory.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { RestListComponent } from './lists/rest-list/rest-list.component';
import { FulfilledListComponent } from './lists/fulfilled-list/fulfilled-list.component';
import { HoldListComponent } from './lists/hold-list/hold-list.component';
import { ReturnListComponent } from './lists/return-list/return-list.component';
import { ReturnComponent } from './return/return.component';
import { RestockedListComponent } from './lists/restocked-list/restocked-list.component';
import { DisposalListComponent } from './lists/disposal-list/disposal-list.component';
import { ReorderListComponent } from './lists/reorder-list/reorder-list.component';
import { OrderCreationComponent } from './ordercreation/ordercreation.component';
import { ReorderInitComponent } from './ordercreation/reorderinit/reorderinit.component';
import { OrderDetailComponent } from './orderdetail/orderdetail.component';
import { OrderDetailOnManuComponent } from './orderdetail/orderdetailonmanu/orderdetailonmanu.component';
import { OrderStatisticsComponent } from './orderstatistics/orderstatistics.component';
import { ExcelorderComponent } from './ordercreation/excelorder/excelorder.component';
import { TestComponent } from './test/test.component';
import { FilterPipe } from './pipe/FilterPipe';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,

    FormsModule,
    SidenavModule,
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    DropdownModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    NavMenuComponent,
    InventoryComponent,
    RestListComponent,
    ReturnComponent,
    FulfilledListComponent,
    HoldListComponent,
    ReturnListComponent,
    RestockedListComponent,
    DisposalListComponent,
    OrderCreationComponent,
    ReorderInitComponent,
    ReorderListComponent,
    ExcelorderComponent,
    OrderDetailComponent,
    OrderDetailOnManuComponent,
    OrderStatisticsComponent,
    TestComponent,
    HomeComponent,
    AdminComponent,
    LoginComponent,
    FilterPipe
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    //MDBSpinningPreloader,
    // provider used to create fake backend
    //fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
