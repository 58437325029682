<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<div class="container">
  <form [formGroup]="shippingForm" (ngSubmit)="loadinfo()">

    <h3>Re-order</h3>
    <table class="fixed">
      <tr>
        <td>Order # :</td>
        <td>
          <input type="number" style="width: 94px;" id="orderno" placeholder="Reorder only" [formControl]="shippingForm.controls['orderno']" (change)="ordernumberchange()" />
        </td>
        <td>
          <button class="button" type="submit">Load Info</button>
        </td>
      </tr>
      <!--<tr>
        <td>New Order # :</td>
        <td>
          <span>{{shippingForm.get('neworderno').value}}</span>
        </td>

      </tr>-->
    </table>
  </form>

  <form [formGroup]="shippingForm">
    <h5>Item Info</h5>
    <table class="table table-bordered table-sm">
      <thead>
        <tr align="center">
          <th scope="col">#</th>
          <th scope="col" for="sku">Sku *</th>
          <th scope="col" for="desc">Description</th>
          <th scope="col" for="qty">Qty *</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of tempitems; let i = index">
          <td align="center">
            <span>{{i+1}}</span>
          </td>
          <td>
            <input type="text" list="skulist" class="form-control" [(ngModel)]="element.sku" [ngModelOptions]="{standalone: true}" (change)="selectSku(i)" />
            <datalist id="skulist">
              <select>
                <option *ngFor="let item of itemlist" [value]="item.sku"></option>
              </select>
            </datalist>
          </td>
          <td>
            <input type="text" list="desclist" class="form-control" [(ngModel)]="element.desc" [ngModelOptions]="{standalone: true}" (change)="selectDesc(i)" />
            <datalist id="desclist">
              <select>
                <option *ngFor="let item of itemlist" [value]="item.desc"></option>
              </select>
            </datalist>
          </td>
          <td>
            <input type="number" class="form-control" [(ngModel)]="element.qty" [ngModelOptions]="{standalone: true}" (change)="qtyChanged(i)" />
          </td>
          <td>
            <span style="padding-right: 5px;">
              <button (click)="addlist()">add</button>
              <button (click)="deletelist(i)">delete</button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>Estimated Weight: {{estimWeight}} (lb)</div>
    <br />
    <h5>Address</h5>
    <table class="table table-bordered table-sm" formGroupName="shipTo">
      <tr>
        <td scope="col">Name <span class="text-danger">*</span></td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="address.name" [ngModelOptions]="{standalone: true}">
        </td>
        <td scope="col">Company</td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="address.company" [ngModelOptions]="{standalone: true}">
        </td>
      </tr>

      <tr>
        <td scope="col">Email</td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="address.email" [ngModelOptions]="{standalone: true}">
        </td>
        <td scope="col">Phone</td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="address.phone" [ngModelOptions]="{standalone: true}">
        </td>
      </tr>
      <tr>
        <td scope="col">Address1</td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="address.address1" [ngModelOptions]="{standalone: true}">
        </td>
        <td scope="col">Address2</td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="address.address2" [ngModelOptions]="{standalone: true}">
        </td>
      </tr>

      <tr>
        <td scope="col">City</td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="address.city" [ngModelOptions]="{standalone: true}">
        </td>
        <td scope="col">State</td>
        <td>
          <input type="text" list="statelist" class="form-control" [(ngModel)]="address.state" [ngModelOptions]="{standalone: true}">
          <datalist id="statelist">
            <select>
              <option *ngFor="let state of statelist" [value]="state.name"></option>
            </select>
          </datalist>
        </td>

      </tr>

      <tr>
        <td scope="col">Country</td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="address.country" [ngModelOptions]="{standalone: true}">
        </td>
        <td scope="col">Zip</td>
        <td>
          <input type="text" class="form-control" [(ngModel)]="address.zip" [ngModelOptions]="{standalone: true}">
        </td>
      </tr>
    </table>

    <h5>Shipping Option</h5>
    <table class="fixed" formArrayName="labels" *ngFor="let label of shippingForm.get('labels').controls; let i=index">
      <tr>
        <td>Shipping Location <span class="text-danger">*</span></td>
        <td>
          <select [formControl]="shippingForm.controls['location']">
            <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td class="r">Shipping Option</td>
        <td>
          <select [(ngModel)]="shippingOption" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let opt of shippingOptions" [ngValue]="opt">{{opt}}</option>
          </select>
        </td>
      </tr>

      <tr [formGroupName]="i">
        <td class="r">Signature</td>
        <td>
          <select formControlName="IsSignature">
            <option [ngValue]="true">True</option>
            <option [ngValue]="false">False</option>
          </select>
        </td>
      </tr>
      <tr [formGroupName]="i">
        <td class="r">Certified</td>
        <td>
          <select formControlName="IsCertified">
            <option [ngValue]="true">True</option>
            <option [ngValue]="false">False</option>
          </select>
        </td>
      </tr>

    </table>
  </form>
  <form [formGroup]="shippingForm">

    <table class="fixed">
      <tr>
        <td class="r">Reason</td>
        <td>
          <select [formControl]="shippingForm.controls['content']">
            <option *ngFor="let opt of reasons" [ngValue]="opt">{{opt}}</option>
          </select>

        </td>
      </tr>
    </table>
  </form>


  <button (click)="submit()">Submit</button>



</div>
