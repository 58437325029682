import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('JwtInterceptor intercept');

    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.authenticationService.userValue;

    //console.log(currentUser);

    const isLoggedIn = currentUser && currentUser.jwtToken;
    //alert(isLoggedIn);
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    //alert(isApiUrl);

    if (isLoggedIn && isApiUrl) {
      //alert('here1');
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.jwtToken}`
        }
      });

      //console.log(JSON.stringify(request));
      //alert('here2');
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        console.log('JwtInterceptortap');

        if (event instanceof HttpResponse && (event.status === 200)) {
          if (request.url.includes('refresh-token')) {
            return;
          }

          const user = this.authenticationService.userValue;
          const isLoggedIn = user && user.jwtToken;
          const isApiUrl = request.url.startsWith(environment.apiUrl);
          if (isLoggedIn && isApiUrl) {
            const jwtToken = JSON.parse(atob(user.jwtToken.split('.')[1]));
            const expires = new Date(jwtToken.exp * 1000);
            const timeout = expires.getTime() - Date.now();
            if (timeout < (60 * 1000 * 15)) {
              console.log('JwtInterceptor refreshTokenTimer', timeout, (60 * 1000 * 15), request.url);
              this.authenticationService.refreshTokenTimer();
            }
          }
        }
      })
    );
  }
}

export function appInitializer(authenticationService: AuthenticationService) {
  return () => new Promise(resolve => {
    // attempt to refresh token on app start up to auto authenticate
    //console.log('appInitializer');
    authenticationService.refreshToken()
      .subscribe()
      .add(resolve);
  });
}
