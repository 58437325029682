<!-- nav -->
<app-nav-menu></app-nav-menu>

<!-- main app container -->
<div class="container-fluid mt-5" style="padding-top:40px;">
  <router-outlet></router-outlet>
</div>

<!-- credits -->
<div class="text-center mt-4">
  <p>
    <a href="https://awi-usa.com" target="_top">© 2021 Advanced Warehouse, Inc. All Rights Reserved.</a>
  </p>
</div>
