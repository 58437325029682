import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { FormGroup } from '@angular/forms';
import { Shipping } from '../Class';

@Injectable({ providedIn: 'root' })

export class ReturnService {

  constructor(private http: HttpClient) { }

  deleteRR(cus, rNo) {
    var url: string = `${environment.apiUrl}/return/DeleteRR`;
    return this.http.post(url, { customer: cus, rMANo: rNo });
  }

  getRRList(formData) {
    var url: string = `${environment.apiUrl}/return/GetRRList`;
    return this.http.post(url, formData);
  }

  getReturnReceivingwithrma(cus, rma) {
    var url: string = `${environment.apiUrl}/return/GetReturnReceivingB2C2`;
    return this.http.post(url, { customer: cus, rMANo: rma });
  }

  getReturnReceivingwithloc(cus, loc) {
    var url: string = `${environment.apiUrl}/return/GetReturnReceiving`;
    return this.http.post(url, { customer: cus, location: loc });
  }

  postReturnReceiving(receivingOrshipping) {
    var url: string = `${environment.apiUrl}/return/PostReturnReceivingInB2C2`;
    return this.http.post(url, receivingOrshipping);
  }

  unholdRR2(cus, rma, sku, qty) {
    var url: string = `${environment.apiUrl}/return/UnHold2`;
    return this.http.post(url, { customer: cus, rMANo: rma, sku: sku, unheldQty: qty });
  }
}
