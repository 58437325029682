import { Component, ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Role, User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { ShippingService } from '@app/_services';
import { FormBuilder } from '@angular/forms';
import { log } from 'util';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import * as moment from 'moment';

@Component({ templateUrl: 'home.component.html', styleUrls: ['./home.component.scss'] })

export class HomeComponent {
  @ViewChild('content', { static: false }) content: ElementRef;
  @ViewChild('content2', { static: false }) content2: ElementRef; 
  loading = false;
  user: User;
  timeout: string;
  mainForm;
 
  isAdmin: boolean;
  statInSEC: [any] = [{}];
  statInCAS: [any] = [{}];
  inventoryInSEC;
  inventoryInCAS;
  showInventory: boolean = false;

  constructor(
    private userService: UserService, private fb: FormBuilder,
    private router: Router,
    private shippingService: ShippingService,
    private authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.userValue;
    console.log(this.user);
    this.getTimeoutOnClick();
    this.isAdmin = (this.user.role === Role.Admin);
    //if (!this.user) {
    ////  this.router.navigate(['/']);
    //  this.router.navigate(['/login']);
    //}
  }

  public chartType: string = 'line';
  public chartDatasetsSEC: Array<any> = [];
  public chartDatasetsCAS: Array<any> = [];
  public chartLabelsSEC: Array<any> = [];
  public chartLabelsCAS: Array<any> = [];
  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 2,
    }
    , {
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 2,
    }
  ];
  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }


  ngOnInit() {
    this.loading = true;
    this.mainForm = this.fb.group({
      customer: this.user.customer,
      endAt: new Date(),
      startAt: this.setDate(),
    });
    this.setInventoryDisplay();
    this.avgOneDayTaskHours();
    if (this.user.customer == 'Krave' || this.user.customer == 'SuperEgg' || this.user.customer == 'Xuyoni' || this.user.customer == 'IDO') {
      this.getInventory();
    }
  }

  setInventoryDisplay() {
    if (this.user.customer == 'Krave' || this.user.customer == 'SuperEgg' || this.user.customer == 'Xuyoni' || this.user.customer == 'IDO') {
      this.showInventory = true;
    } else {
      this.showInventory = false;
    }
  }

  setDate(): Date {
    var now = new Date();
    var startAt = new Date(new Date().setDate(new Date().getDate() - 4));
    var result = new Date(new Date().setDate(new Date().getDate() - 4));
  
    for (var d = startAt; d <= now; d.setDate(d.getDate() + 1)) {
      if (d.getDay() == 6 || d.getDay() == 0) {
        result.setDate(result.getDate() - 1);
      }
    }
    return result;
  }

  avgOneDayTaskHours() {
    var startAt = moment(this.mainForm.get('startAt').value).format('YYYYMMDD');
    var endAt = moment(this.mainForm.get('endAt').value).format('YYYYMMDD');
    this.shippingService.getFulfillmentReport(startAt, endAt).subscribe(res => {
      this.statInSEC = res['secStat'];
      this.statInCAS = res['casStat'];
      console.log(res);
      this.chartDatasetsSEC = [{ data: this.statInSEC.map(x => parseInt(x.fulfilledRate)), label: 'Fulfillment Rate (%)' },
      { data: this.statInSEC.map(x => parseInt(x.turnAroundTime)), label: 'Avg Hours' },
      ];
      this.chartLabelsSEC = this.statInSEC.map(x => this.dateFormater(x.date));
      this.chartDatasetsCAS = [{ data: this.statInCAS.map(x => parseInt(x.fulfilledRate)), label: 'Fulfillment Rate (%)' },
      { data: this.statInCAS.map(x => parseInt(x.turnAroundTime)), label: 'Avg Hours' },
      ];
      this.chartLabelsCAS = this.statInCAS.map(x => this.dateFormater(x.date));
    });
  }

  dateFormater(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  getInventory() {
    this.shippingService.getInventoryBySku(this.mainForm.value).subscribe(data => {
      this.inventoryInSEC = data['inventoryInSEC'];
      this.inventoryInCAS = data['inventoryInCAS'];
      console.log(data);
    });
  }

  exportPDF() {
    let DATA1 = this.content.nativeElement;
    let DATA2 = this.content2.nativeElement;
    let doc = new jsPDF("l", "mm", "a4");
    var thetime = new Date();
    var datetime = `Date printed: ${thetime}`;
    html2canvas(DATA1).then(canvas => {
      var image1 = canvas.toDataURL('image/png', 0.5);
      var imgProps = doc.getImageProperties(image1);
      var width = doc.internal.pageSize.getWidth();
      var height = (imgProps.height * width) / imgProps.width;
      doc.setFontSize(8);
      doc.text(datetime, 5, doc.internal.pageSize.height - 3);
      doc.addImage(image1, 'png', 0, 0, width, height);
      //doc.save('dashboard.pdf');
    });
    html2canvas(DATA2).then(canvas => {
      var image2 = canvas.toDataURL('image/png', 0.5);
      var imgProps = doc.getImageProperties(image2);
      var width = doc.internal.pageSize.getWidth();
      var height = (imgProps.height * width) / imgProps.width;

      doc.addPage();
      doc.setFontSize(8);

      doc.text(datetime, 5, doc.internal.pageSize.height - 3);
      doc.addImage(image2, 'png', 0, 0, width, height);
      doc.save('dashboard.pdf');
      
    });
    

  }

  getTimeoutOnClick() {
    console.log('HomeComponent getTimeoutOnClick');
    console.log('this.authenticationService.tokenTimeOut', this.authenticationService.tokenTimeOut);
    this.timeout = this.authenticationService.tokenTimeOut;
  }

  refreshToken() {
    this.authenticationService.refreshTokenTimer();
  }
}
