import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { RestListComponent } from './lists/rest-list/rest-list.component';
import { FulfilledListComponent } from './lists/fulfilled-list/fulfilled-list.component';
import { HoldListComponent } from './lists/hold-list/hold-list.component';
import { ReturnListComponent } from './lists/return-list/return-list.component';
import { ReturnComponent } from './return/return.component';
import { RestockedListComponent } from './lists/restocked-list/restocked-list.component';
import { DisposalListComponent } from './lists/disposal-list/disposal-list.component';
import { OrderCreationComponent } from './ordercreation/ordercreation.component';
import { ReorderInitComponent } from './ordercreation/reorderinit/reorderinit.component';
import { ReorderListComponent } from './lists/reorder-list/reorder-list.component';
import { OrderDetailComponent } from './orderdetail/orderdetail.component';
import { OrderDetailOnManuComponent } from './orderdetail/orderdetailonmanu/orderdetailonmanu.component';
import { OrderStatisticsComponent } from './orderstatistics/orderstatistics.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ExcelorderComponent } from './ordercreation/excelorder/excelorder.component';
import { TestComponent } from './test/test.component';

import { Role } from './_models';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
  { path: 'login', component: LoginComponent },
  { path: 'rest-list', component: RestListComponent, canActivate: [AuthGuard] },
  { path: 'rest-list/:customer', component: RestListComponent, canActivate: [AuthGuard] },
  { path: 'rest-list/:customer/:location', component: RestListComponent, canActivate: [AuthGuard] },
  { path: 'reorder-list', component: ReorderListComponent, canActivate: [AuthGuard] },
  { path: 'fulfilled-list', component: FulfilledListComponent, canActivate: [AuthGuard] },
  { path: 'return-list', component: ReturnListComponent, canActivate: [AuthGuard] },
  { path: 'return', component: ReturnComponent, canActivate: [AuthGuard] },
  { path: 'restocked-list', component: RestockedListComponent, canActivate: [AuthGuard] },
  { path: 'disposal-list', component: DisposalListComponent, canActivate: [AuthGuard] },
  { path: 'ordercreation', component: OrderCreationComponent, canActivate: [AuthGuard] },
  { path: 'reorderinit/:rrno/:no', component: ReorderInitComponent, canActivate: [AuthGuard] },
  { path: 'excelorder', component: ExcelorderComponent, canActivate: [AuthGuard] },
  { path: 'hold-list', component: HoldListComponent, canActivate: [AuthGuard] },
  { path: 'orderdetail/:no', component: OrderDetailComponent, canActivate: [AuthGuard] },
  { path: 'orderdetail', component: OrderDetailComponent, canActivate: [AuthGuard] },
  { path: 'orderstatistics', component: OrderStatisticsComponent, canActivate: [AuthGuard] },
  { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard] },


  { path: 'test', component: TestComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
