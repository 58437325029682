<h3>On Hold Orders</h3>
  <div class="float-left" style="padding-top: 5px;">
    <mdb-checkbox [(ngModel)]="filterAddressError" (change)="filterAddressErrors($event)">Filter Only Address Issue</mdb-checkbox>
  </div>
  <div class="row float-right">
    <button mdbBtn type="button" color="blue-grey" rounded="true" class="btn btn-success" size="sm" (click)="unHoldMultipleOrders()" mdbWavesEffect>Unhold Selected Items</button>
  </div>


<table class="table table-bordered table-sm table-hover">
  <thead>
    <tr align="center">
      <th>
        <mdb-checkbox [(ngModel)]="mastercheck" (change)="masterCheck()"></mdb-checkbox>
      </th>
      <th scope="col">Customer #</th>
      <th scope="col">Order #</th>
      <th scope="col">Risk Level</th>
      <th scope="col">On Hold</th>
      <th scope="col">Address Error</th>
      <th scope="col">Name / email</th>
      <th scope="col">Items</th>
      <th scope="col">Price</th>
      <th scope="col">State</th>
      <th scope="col">Order at</th>
      <th scope="col">Paid at</th>
      <th scope="col">Unhold</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let el of elements  ; let i = index">
      <td style="text-align:center;">
        <!--<input type="checkbox" [checked]="el.checked" value="el.id" (change)="createCheckedList($event, el.id)" />-->
        <mdb-checkbox [(ngModel)]="el.checked" (change)="createCheckedList($event, el.id)"></mdb-checkbox>
      </td>
      <td>{{el.customerNo}}</td>
      <td>
        <a [routerLink]="['/orderdetail',el.orderNo]">{{el.orderNo}}</a>
      </td>
      <td>{{el.riskLevel}}</td>
      <td>
        <span *ngIf="el.isHold" class="red-text">{{el.isHold}}</span>
        <span *ngIf="!el.isHold">{{el.isHold}}</span>
      </td>
      <td>
        <span *ngIf="el.hasAddressError" class="red-text">{{el.hasAddressError}}</span>
        <span *ngIf="!el.hasAddressError">{{el.hasAddressError}}</span>
      </td>
      <td>
        <span *ngIf="el.shipTo">{{el.shipTo.name}}</span><br />
        <span *ngIf="el.shipTo">{{el.shipTo.email}}</span>
      </td>
      <td>
        <div *ngFor="let item of el.items;">
          {{item.sku}} ({{item.qty}})
        </div>
      </td>
      <td>{{el.totalPrice }}</td>
      <td>{{el.shipTo.state }}</td>
      <td>{{el.orderAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td>{{el.paidAt | date:'MM/dd/yyyy HH:mm'}}</td>
      <td>
        <button mdbBtn type="button" class="btn-sm" color="mdb-color" mdbWavesEffect (click)="unHold(el.orderNo)">UnHold</button>
      </td>
    </tr>
  </tbody>
</table>

  

  


