import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DatePipe, Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { ShippingService } from '@app/_services';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-rest-list',
  templateUrl: './rest-list.component.html',
  styleUrls: ['./rest-list.component.scss']
})

export class RestListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'RestList.xlsx');
  }

  currentUser:User;
  elements: any = [];
  total: number;
  mastercheck: boolean = false;
  
  isLoading: boolean = false;

  mainForm;
  memoForm;

  locations: string[] = ["All", "AWISEC", "AWICAS"];
//  customers: string[] = ["Krave", "Jayjun", "ToyoLife", "Spigen", "HoneyJarret", "Arencia", "CrossPoint"];
  locationOptionsSelect: object[] = [
    { value: 'All', label: 'All' },
    { value: 'AWICAS', label: 'AWICAS' },
    { value: 'AWISEC', label: 'AWISEC' },
  ];

  //location: AbstractControl;

  constructor(
    //public datepipe: DatePipe,
    private fb: FormBuilder, private http: HttpClient,
    private shippingService: ShippingService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService) {

  }

  ngOnInit() {
    this.currentUser = this.authenticationService.userValue;
    this.activatedRoute.paramMap.subscribe(params => {
      this.mainForm = this.fb.group({
        customer: this.currentUser.customer,
        location: params.get('location') ? params.get('location') : 'All',
        page: 1
      });
      this.onSubmit();
    })
    
    this.memoInit();
  }

  createCheckedList(value: any, id: string) {
    var element = this.elements.filter(x => x.id == id)[0];
    if (element) {
      element.checked = !element.checked;
      console.log(element.checked);
    }

  }

  masterCheck() {
    this.mastercheck = this.mastercheck != true;

    console.log(this.mastercheck);
    (this.elements).map(x => {
      x.checked = this.mastercheck;
      console.log(x.checked);
    });
  }

 

  memoInit() {
    this.memoForm = this.fb.group({
      customer: this.currentUser.customer,
      orderNo: '',
      content: ''
    });
  }

  holdShipping() {
    console.log(this.elements);
    const selectedIdList = (this.elements).map(x => {
      if (x.checked) {
        return x.id;
      }
      return null;
    }).filter(x => x);
    console.log(selectedIdList);
    if (selectedIdList.length > 0) {
      this.shippingService.holdMultipleOrders(selectedIdList).subscribe(data => {
        alert(`Successfully status changed for ${selectedIdList} order${selectedIdList.length > 1 ? "s" : ""}.`);
        this.onSubmit();
      });
    }
    else {
      alert(`Couldn't find any selected order. \r\nPlease select more than one order and try again.`);
    }
    
    
  }

  onSubmit() {
    this.isLoading = true;
    this.location.replaceState(`/rest-list/${this.mainForm.value.customer}/${this.mainForm.value.location}`);
    this.shippingService.getRestList(this.mainForm.value)
      //.pipe(first())
      .subscribe(data => {
        this.isLoading = false;
        this.elements = data;
        this.total = this.elements.length;
        console.log('succeed');
      });

    //this.checkoutForm.reset();
  }

  onMemoSubmit(customerData) {
    console.warn(customerData);

    if (this.memoForm.valid) {
      this.shippingService.memo(this.memoForm.value).subscribe(data => {
        //console.log(data);

        if (data["isSuccess"]) {
          this.memoInit();
          this.onSubmit();
        }
        else {
          alert(data["errorMsg"]);
        }
      });
    }
    else {
      alert(this.memoForm.status);
    }

    //this.checkoutForm.reset();
  }

  //loading = false;
  //currentUser: User;
  //userFromApi: User;

  //constructor(
  //    private userService: UserService,
  //    private authenticationService: AuthenticationService
  //) {
  //  this.currentUser = this.authenticationService.currentUserValue;
  //  console.log(this.currentUser);
  //}

  //ngOnInit() {
  //    this.loading = true;
  //    this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
  //        this.loading = false;
  //        this.userFromApi = user;
  //    });
  //}
}
