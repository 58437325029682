<div class="card mt-4" id="content">
  <h4 class="card-header">Dashboard</h4>
  <div class="card-body">
    <!--<p>You're logged in with Angular 8 & JWT!!</p>
  <p>Your role is: <strong>{{currentUser.role}}</strong>.</p>
  <p>This page can be accessed by <u>all authenticated users</u>.</p>
  <p class="mb-1">Current user from secure api end point:</p>
  <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>-->

    <div *ngIf="user">
      <p>Hello, {{user.firstName}} {{user.lastName}} !</p>
    </div>
    <div class="export-button-container">
      <button (click)="exportPDF()">PDF Export</button>
    </div>
    <div class="row"  id="content" #content>
      <div class="col-sm-6 mb-3 mb-md-0">
        <mdb-card class="card-container">
          <mdb-card-body>
            <mdb-card-title>
              <h4>Daily Order Summary (NJ)</h4>
            </mdb-card-title>
            <mdb-card-text>
              <div style="display: block">
                <canvas mdbChart
                        [chartType]="chartType"
                        [datasets]="chartDatasetsSEC"
                        [labels]="chartLabelsSEC"
                        [colors]="chartColors"
                        [options]="chartOptions"
                        [legend]="true"
                        (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)">
                </canvas>
              </div>
              <table class="table table-bordered table-sm" style="margin-top: 10px;">
                <thead>
                  <tr align="center" style="text-align: center; font-weight:bold; background-color: lightgray;">
                    <td>Date</td>
                    <td>NJ Fulfilled Orders</td>
                    <td>Turn Around Time (H)</td>
                    <td>Fulfillment Rate (%)</td>
                  </tr>
                </thead>
                <tbody>
                  <tr align="center" *ngFor="let el of statInSEC; let i = index">
                    <td>{{dateFormater(el.date)}}</td>
                    <td>{{el.numberOfFulfilledOrders}}</td>
                    <td>{{el.turnAroundTime}}</td>
                    <td>{{el.fulfilledRate}}</td>
                  </tr>
                </tbody>
              </table>
            </mdb-card-text>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-sm-6">
        <mdb-card class="card-container">
          <mdb-card-body>
            <mdb-card-title>
              <h4>Daily Order Summary (CA)</h4>
            </mdb-card-title>
            <mdb-card-text>
              <div style="display: block">
                <canvas mdbChart
                        [chartType]="chartType"
                        [datasets]="chartDatasetsCAS"
                        [labels]="chartLabelsCAS"
                        [colors]="chartColors"
                        [options]="chartOptions"
                        [legend]="true"
                        (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)">
                </canvas>
              </div>
              <table class="table table-bordered table-sm" style="margin-top: 10px;">
                <thead>
                  <tr style="text-align: center; font-weight:bold; background-color: lightgray;">
                    <td>Date</td>
                    <td>CA Fulfilled Orders</td>
                    <td>Turn Around Time (H)</td>
                    <td>Fulfillment Rate (%)</td>
                  </tr>
                </thead>
                <tbody>
                  <tr align="center" *ngFor="let el of statInCAS; let i = index">
                    <td>{{dateFormater(el.date)}}</td>
                    <td>{{el.numberOfFulfilledOrders}}</td>
                    <td>{{el.turnAroundTime}}</td>
                    <td>{{el.fulfilledRate}}</td>
                  </tr>
                </tbody>
              </table>
            </mdb-card-text>
          </mdb-card-body>
        </mdb-card>
      </div>
      </div>
      <div class="row" *ngIf="showInventory" id="content2" #content2>

        <div class="col-sm-6 mb-3 mb-md-0" style="padding-top:30px;">
          <mdb-card class="card-container">
            <mdb-card-body>
              <mdb-card-title>
                <h4>Inventory Overview (NJ)</h4>
              </mdb-card-title>
              <mdb-card-text>
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr style="text-align: center; font-weight:bold; background-color: lightgray;">
                      <td>Sku</td>
                      <td>Description</td>
                      <td>On Hand</td>
                      <td>Available</td>
                      <td>Avg Qty / Day</td>
                      <td>Days to Cover</td>
                      <td>Estimated Stock Out Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let el of inventoryInSEC; let i = index">
                      <td>{{el.sku}}</td>
                      <td>{{el.description}}</td>
                      <td style="text-align:center;">{{el.onHandQty}}</td>
                      <td style="text-align:center;">{{el.availableQty}}</td>
                      <td style="text-align:center;">{{el.avgSalesQty}}</td>
                      <td style="text-align:center;">{{el.daysToCover}}</td>
                      <td style="text-align:center;">{{el.expectedStockOut | date:'MM-dd-yy':'GMT'}}</td>
                    </tr>
                  </tbody>
                </table>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div class="col-sm-6" style="padding-top:30px;">
          <mdb-card class="card-container">
            <mdb-card-body>
              <mdb-card-title>
                <h4>Inventory Overview (CA)</h4>
              </mdb-card-title>
              <mdb-card-text>
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr style="text-align: center; font-weight:bold; background-color: lightgray;">
                      <td>Sku</td>
                      <td>Description</td>
                      <td>On Hand</td>
                      <td>Available</td>
                      <td>Avg Qty / Day</td>
                      <td>Days to Cover</td>
                      <td>Estimated Stock Out Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let el of inventoryInCAS; let i = index">
                      <td>{{el.sku}}</td>
                      <td>{{el.description}}</td>
                      <td style="text-align:center;">{{el.onHandQty}}</td>
                      <td style="text-align:center;">{{el.availableQty}}</td>
                      <td style="text-align:center;">{{el.avgSalesQty }}</td>
                      <td style="text-align:center;">{{el.daysToCover}}</td>
                      <td style="text-align:center;">{{el.expectedStockOut | date:'MM-dd-yy':'GMT'}}</td>
                    </tr>
                  </tbody>
                </table>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>

    </div>

  
</div>


