
<h3>Fulfilled Orders</h3>

<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>

<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" class="float-left">

  <div class="row" style="padding-left:20px; padding-bottom:5px; padding-right:10px;">
    <div style="padding-right:15px;padding-top:5px;">
      <select formControlName="location" class="browser-default custom-select" style="width: 100px;font-size: small;">
        <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
      </select>
    </div>
  

    <div style="padding-left:20px;padding-right:10px;">
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'From'" formControlName="startAt" required></mdb-date-picker>

    </div>
    <div>
      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'To'" formControlName="endAt" required></mdb-date-picker>
    </div>
    <div style="padding-left:15px;padding-right:15px;">
      <button mdbBtn class="btn btn-amber" size="sm" type="submit" mdbWavesEffect>Submit</button>
    </div>

  </div>
  <div>Total orders: {{total}}</div>
</form>



<div #TABLE #table>
  <div style="padding-bottom: 5px;padding-top: 5px;">
    <div class="row float-right">
      <div align="right">
        <button mdbBtn type="button" color="success" rounded="true" (click)="ExportTOExcel()" class="btn btn-success" size="sm" mdbWavesEffect>Excel</button>
      </div>
    </div>
  </div>

  <table class="table table-bordered table-sm table-hover">
    <thead>
      <tr>
        <th scope="col">Customer #</th>
        <th scope="col">Order #</th>
        <th scope="col">Location</th>
        <th scope="col">On Hold</th>
        <th scope="col">Name / Email</th>
        <th scope="col">Items</th>
        <th scope="col">Qty</th>
        <th scope="col">Shipping Option</th>
        <th scope="col">Tracking #</th>
        <th scope="col">Status</th>
        <th scope="col">En-Route <br /> Days</th>
        <th scope="col">Risk Level</th>
        <th scope="col">State</th>
        <th scope="col">Order at</th>
        <th scope="col">Paid at</th>
        <th scope="col">Closed at</th>
        <th scope="col">Log</th>
        <th scope="col">Memo</th>
      </tr>
    </thead>
    <tbody *ngFor="let el of elements; let i = index">
      <tr mdbTableCol *ngFor="let item of el.items; let j = index">

        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.customerNo}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.customerNo}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
          <a [routerLink]="['/orderdetail',el.orderNo]">{{el.orderNo}}</a>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">
          {{el.orderNo}}
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:1%;" *ngIf="j==0">{{el.location}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="width:1%; color:transparent;border-top:hidden">{{el.location}}</td>

        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:1%;" *ngIf="j==0">
          <span *ngIf="el.isHold" class="red-text">{{el.isHold}}</span>
          <span *ngIf="!el.isHold">{{el.isHold}}</span>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="width:1%; color:transparent;border-top:hidden">
          <span *ngIf="el.isHold" class="red-text">{{el.isHold}}</span>
          <span *ngIf="!el.isHold">{{el.isHold}}</span>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:16%;" *ngIf="j==0">
          <span *ngIf="el.shipTo">{{el.shipTo.name}}</span> /
          <span *ngIf="el.shipTo">{{el.shipTo.email}}</span>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:16%;" *ngIf="j>0" style="color:transparent;border-top:hidden">
          <span *ngIf="el.shipTo">{{el.shipTo.name}}</span> /
          <span *ngIf="el.shipTo">{{el.shipTo.email}}</span>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'">
          {{item.sku}}
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'">
          {{item.qty}}
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.shippingOption}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.shippingOption}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
          <div *ngFor="let label of el.labels;">

            &#8203;{{label.trackingNo}}
          </div>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">
          <div *ngFor="let label of el.labels;">

            &#8203;{{label.trackingNo}}
          </div>
        </td>
        <td *ngIf="j==0">
          <div *ngFor="let label of el.labels">
            {{lastTrackingInfo(label.tracks)}}
          </div>
        </td>
        <td *ngIf="j>0" style="color:transparent;border-top:hidden"> </td>
        <td *ngIf="j==0">
          <div *ngFor="let label of el.labels;">
            {{countingEnRouteDays(label.tracks)}}
          </div>
        </td>
        <td *ngIf="j>0" style="color:transparent;border-top:hidden">
          0
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.riskLevel}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.riskLevel}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0"> <span *ngIf="el.shipTo">{{el.shipTo.state}}</span></td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden"> <span *ngIf="el.shipTo">{{el.shipTo.state}}</span></td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.orderAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.orderAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.paidAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.paidAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">{{el.closedAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">{{el.closedAt | date:'MM/dd/yyyy HH:mm'}}</td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:10%;" *ngIf="j==0">
          <div *ngFor="let log of logFilter(el.logs);">
            {{log.content}} ({{log.createdAt | date:'MM/dd/yyyy HH:mm'}})
          </div>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" style="width:10%;" *ngIf="j>0" style="color:transparent;border-top:hidden">
          <div *ngFor="let log of logFilter(el.logs);">
            {{log.content}} ({{log.createdAt | date:'MM/dd/yyyy HH:mm'}})
          </div>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j==0">
          <div *ngFor="let memo of el.memoes;" style="text-wrap:normal;">
            {{memo.content}}({{memo.createdAt | date:'MM/dd/yyyy HH:mm'}})
          </div>
        </td>
        <td [class.bg-danger]="el.riskLevel=='HIGH'" [class.bg-warning]="el.riskLevel=='MEDIUM'" *ngIf="j>0" style="color:transparent;border-top:hidden">
          <div *ngFor="let memo of el.memoes;" style="text-wrap:normal;">
            {{memo.content}}({{memo.createdAt | date:'MM/dd/yyyy HH:mm'}})
          </div>
        </td>
      </tr>
    </tbody>
  </table>
 

</div>


