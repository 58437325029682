import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '@app/_models';
import { UserService, AuthenticationService, ShippingService } from '@app/_services';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-disposal-list',
  templateUrl: './disposal-list.component.html',
  styleUrls: ['./disposal-list.component.scss']
})

export class DisposalListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'DisposalList.xlsx');
  }
  private userSubject: BehaviorSubject<User>;
  public get userValue(): User {
    return this.userSubject.value;
  }

 

  currentUser:User;

  elements: any = [];
 
  constructor(private fb: FormBuilder, 
    private http: HttpClient, 
    private router: Router, 
    private shippingService: ShippingService,
    private authenticationService: AuthenticationService) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
  }

  ngOnInit() {
    this.shippingService.getDisposal().subscribe(list => {
      this.elements = list;
      console.log(list);
    })
  }

 
 
 

 
}
