<div *ngIf="isTest">istest</div>
  <div class="container">
    <form [formGroup]="shippingForm" (ngSubmit)="loadinfo(shippingForm.value)">

      <h3>test formarray</h3>
      <table class="fixed">
        <tr>
          <td>Customer : </td>
          <td>Krave </td>
          <!--<td>
          <select id="customer" [formControl]="shippingForm.controls['customer']" (change)="customerchange()">
            <option *ngFor="let opt of customers" [ngValue]="opt">{{opt}}</option>
          </select>
        </td>-->
        </tr>
        <tr>
          <td>Order # :</td>
          <td>
            <input type="number" style="width: 94px;" id="orderno" placeholder="Order #" [formControl]="shippingForm.controls['orderno']" />
            <!--(change)="ordernumberchange()"-->
          </td>
          <td>
            <button class="button" type="submit">Load Info</button>
          </td>
        </tr>
      </table>

      <h5>Item Info</h5>
      <table class="table table-bordered table-sm">
        <thead>
          <tr align="center">
            <th scope="col">#</th>
            <th scope="col" for="sku">Sku *</th>
            <th scope="col" for="desc">Description</th>
            <th scope="col" for="qty">Qty *</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody formArrayName="items" *ngFor="let element of shippingForm.get('items').controls; let i = index">
          <tr [formGroupName]="i">
            <td align="center">
              <span>{{i+1}}</span>
            </td>
            <td>
              <input type="text" list="skulist" formControlName="sku" (change)="selectSku(i)" />
              <datalist id="skulist">
                <select>
                  <option *ngFor="let item of itemlist" [value]="item.sku"></option>
                </select>
              </datalist>
            </td>
            <td>
              <input type="text" list="desclist" formControlName="desc" (change)="selectDesc(i)" />
              <datalist id="desclist">
                <select>
                  <option *ngFor="let item of itemlist" [value]="item.desc"></option>
                </select>
              </datalist>
            </td>
            <td>
              <input type="number" formControlName="qty">
            </td>
            <td>
              <span style="padding-right: 5px;">
                <button (click)="add()">add</button>
                <button (click)="delete(i)">delete</button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

     
        <h5>Address</h5>
        <table class="table table-bordered table-sm" formArrayName="shipTo" *ngFor="let element of shippingForm.get('shipTo').controls; let i = index">
          <tr [formGroupName]="i">
            <td scope="col">Name *</td>
            <td>
              <input type="text" formControlName="name">
            </td>
            <td scope="col">Company</td>
            <td>
              <input type="text" formControlName="company">
            </td>
          </tr>
        
          <tr [formGroupName]="i">
            <td scope="col">Email</td>
            <td>
              <input type="text" formControlName="email">
            </td>
            <td scope="col">Phone</td>
            <td>
              <input type="text" formControlName="phone">
            </td>
          </tr>
          <tr [formGroupName]="i">
            <td scope="col">Address1</td>
            <td>
              <input type="text" formControlName="address1">
            </td>
            <td scope="col">Address2</td>
            <td>
              <input type="text" formControlName="address2">
            </td>
          </tr>
        
          <tr [formGroupName]="i">
            <td scope="col">City</td>
            <td>
              <input type="text" formControlName="city">
            </td>
            <td scope="col">State</td>
            <td>
              <input type="text" list="statelist" formControlName="state">
              <datalist id="statelist">
                <select>
                  <option *ngFor="let state of statelist" [value]="state.name"></option>
                </select>
              </datalist>
            </td>
           
          </tr>
        
        
          <tr [formGroupName]="i">
            <td scope="col">Country</td>
            <td>
              <input type="text" formControlName="country">
            </td>
            <td scope="col">Zip</td>
            <td>
              <input type="text" formControlName="zip">
            </td>
          </tr>
         
        </table>
        <h5>Shipping Option</h5>
        <table class="fixed" formArrayName="labels" *ngFor="let label of shippingForm.get('labels').controls; let i=index">
          <tr>
            <td class="r">Shipping Location*</td>
            <td>
              <select [formControl]="shippingForm.controls['location']">
                <option *ngFor="let opt of locations" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
          </tr>
          <tr [formGroupName]="i">
            <td class="r">Carrier</td>
            <td>
              <select formControlName="Carrier">
                <option *ngFor="let opt of carriers" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
          </tr>
          <tr [formGroupName]="i">
            <td class="r">Shipping Option</td>
            <td>
              <select formControlName="ShippingOption">
                <option [hidden]="label.Carrier=='FedEx'" *ngFor="let opt of endishippingopt" [ngValue]="opt">{{opt}}</option>
                <option [hidden]="label.Carrier=='Endicia'" *ngFor="let opt of fedshippingopt" [ngValue]="opt">{{opt}}</option>
              </select>
            </td>
          </tr>
          <tr [formGroupName]="i">
            <td class="r">Signature</td>
            <td>
              <select formControlName="IsSignature">
                <option [ngValue]="true">True</option>
                <option [ngValue]="false">False</option>
              </select>
            </td>
          </tr>
          <tr [formGroupName]="i">
            <td class="r">Certified</td>
            <td>
              <select formControlName="IsCertified">
                <option [ngValue]="true">True</option>
                <option [ngValue]="false">False</option>
              </select>
            </td>
          </tr>
        </table>

        <button (click)="submit(shippingForm.value)">Submit</button>
 

    </form>
  </div>
