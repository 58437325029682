import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '@app/_models';
import { UserService, AuthenticationService, ShippingService } from '@app/_services';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { ReturnService } from '../_services/return.service';



@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.scss']
})

export class ReturnComponent {

  shipping: any;
  receiving: any;
  RMANo: string;
  retruncodes: string[] = ["A - Item Damaged",
    "B - Wrong Item Shipped", "C - Not as Described",
    "D - Not as Expected", "E - Do not Want",
    "INSUFFICIENT ADDRESS", "ATTEMPTED - NOT KNOWN", "REFUSED", "UNCLAIMED", "OTHER"];
  returncodeinputs: string[];
  returncode: string;
  iteminputs: RMAList[] = [];
  itemList: any[] = [];
  barcodeValue: string;
  locations: string[] = ["AWISEC", "AWICAS"];
  customer: string;
  location: string;
  trackingNo: string;

  @ViewChild('input1') inputEl1: ElementRef;
  @ViewChild('input2') inputEl2: ElementRef;
  @ViewChild('input3') inputEl3: ElementRef;

  private userSubject: BehaviorSubject<User>;
  public get userValue(): User {
    return this.userSubject.value;
  }

  currentUser: User;

  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private shippingService: ShippingService,
    private returnService: ReturnService,
    private authenticationService: AuthenticationService) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.userValue;
    setTimeout(() => this.inputEl3.nativeElement.focus());
  }

  addRow() {
    this.shipping.items.push(new RMAList);
  }

  deleteRow(index: number) {
    this.shipping.items.splice(index, 1);
  }

  onBarcodeChange(event: string): void {

    if (this.location == "" || this.location == null) {
      alert('please choolse location.');
      return;
    }
    console.log(event.trim());
    this.RMANo = event.trim();
    this.returnService.getReturnReceivingwithrma(this.currentUser.customer, this.RMANo).subscribe(response => {
      if (response["isSuccess"]) {
        alert('This return request exists.')
      } else {
        this.shipping = response["data"];
        this.shipping.location = this.location;
        this.shipping.items.push(new RMAList);
        console.log('before printing shipping');
        console.log(this.shipping);
      }
    });

    this.shippingService.getItemList(this.currentUser.customer).subscribe(data => {
      this.itemList = data as any[];
      console.log(this.itemList);
    });
  }


  onCompletedwithRMA() {
    //if (this.shipping.items.length == 0) {
    //  alert('no items to be received.');
    //  return;
    //}
    console.log(this.shipping.items);
    if (this.shipping.items.length > 0) {
      if (this.shipping.items.find(x => x.grade == null) != null) {
        alert('Return Code is a required field.')
        return;
      }
    }

    for (let i = 0; i < this.shipping.items.length; i++) {
      this.shipping.items[i].statusSign = "I";
    }
    this.shipping.trackingNo = this.trackingNo;
    this.shipping.customer = this.currentUser.customer;
    //this.shipping.items = this.iteminputs;
    this.returnService.postReturnReceiving(this.shipping).subscribe(response => {
      if (response["isSuccess"]) {
        console.log(response["data"]);
        alert('returned successfully.');
        this.onExit();
      } else {
        alert(response["errorMsg"]);
      }
    });
  }


  onSubmit() {
    if (this.location == null) {
      alert('please select customer and/or location.');
      return;
    }

    this.returnService.getReturnReceivingwithloc(this.currentUser.customer, this.location)
      .subscribe(response => {
        if (response["isSuccess"]) {
          console.log(response["data"]);
          this.receiving = response["data"];
          setTimeout(() => this.inputEl2.nativeElement.focus());
        }
      });

    this.shippingService.getItemList(this.currentUser.customer).subscribe(data => {
      this.itemList = data as any[];
      console.log(this.itemList);
    });

  }

  onExit() {
    this.shipping = null;
    this.receiving = null;
    this.iteminputs = [];
    setTimeout(() => this.inputEl3.nativeElement.focus());
  }

  selectSku(index: number) {
    var anotherindex = this.itemList.findIndex(i => i.sku == this.shipping.items[index].sku);
    this.shipping.items[index].desc = this.itemList[anotherindex].desc;
  }

  selectDesc(index: number) {
    var anotherindex = this.itemList.findIndex(i => i.desc == this.shipping.items[index].desc);
    this.shipping.items[index].sku = this.itemList[anotherindex].sku;
  }

}

class RMAList {
  //  id: string;
  receiptqty: number;
  sku: string;
  qty: number;
  upc: string;
  //  upc2: string;
  desc: string;
  //  weight: number;
  customer: string;
  //  isEditable: boolean = false;
  grade: string;
  note: string;
  expirationDate: Date;
  statusSign: string;
}
