<nav class="navbar navbar-expand fixed-top navbar-dark bg-dark" *ngIf="currentUser">
  <div class="navbar-nav">
    <div class="logo-container"><a href="/"><span class="awi-logo "><img src="/assets/images/logo-awi.png"></span></a></div>
    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Order List<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/rest-list']">Rest List</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/fulfilled-list']">Fulfilled List</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/hold-list']">On Hold List</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/reorder-list']">Re-order List</a>
      </div>
    </li>


    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Returns<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/return-list']">Pending</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/restocked-list']">Closed</a>
      </div>
    </li>
    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" (click)="ping()" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Order Creation<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/ordercreation']">Re-order</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/excelorder']">Order Upload</a>
      </div>
    </li>

    <a class="nav-item nav-link" (click)="ping()" [routerLink]="['/orderdetail']">Order Detail</a>
    <li class="nav-item dropdown" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" (click)="ping()" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
        Reports<span class="caret"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/inventory']">Inventory</a>
        <a class="dropdown-item waves-light" (click)="ping()" mdbWavesEffect [routerLink]="['/orderstatistics']">Order Summary</a>
      </div>
    </li>
    <a class="nav-item nav-link" (click)="ping()" routerLink="/admin" *ngIf="isAdmin">Admin</a>
    <a class="nav-item nav-link" (click)="logout()">Logout</a>


  </div>
</nav>
