import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { User } from '@app/_models';
import { UserService, AuthenticationService, ShippingService } from '@app/_services';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-return-list',
  templateUrl: './return-list.component.html',
  styleUrls: ['./return-list.component.scss']
})

export class ReturnListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ReturnList.xlsx');
  }
  private userSubject: BehaviorSubject<User>;
  public get userValue(): User {
    return this.userSubject.value;
  }

  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  currentUser:User;

  mainForm;
  memoForm;

  elements: any = [];
  total: number;
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  isLoading: boolean = false;

  constructor(private fb: FormBuilder, 
    private http: HttpClient, 
    private router: Router,
    private location: Location, private activatedRoute: ActivatedRoute,
    private shippingService: ShippingService,
    private authenticationService: AuthenticationService) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.userValue;

    var startAt = new Date(new Date().setHours(0, 0, 0, 0))
    startAt.setMonth(startAt.getMonth() - 1);

    this.activatedRoute.paramMap.subscribe(params => {
      this.mainForm = this.fb.group({
        customer: this.currentUser.customer,
        location: params.get('location') ? params.get('location') : 'All',
        startAt: startAt,
        endAt: new Date(new Date().setHours(0, 0, 0, 0))
      });
      this.onSubmit();
    })
    this.memoInit();
  }

  attention(receivedNo, sku, grade) {
    console.log(receivedNo, sku, grade);
    this.shippingService.putAttention(this.mainForm.get('customer').value, receivedNo, sku, grade).subscribe(response => {
      if (response['isSuccess'] == true) {
        alert('attention done.');
        this.onSubmit();
      } else {
        alert(response['errorMsg']);
        this.onSubmit();
      }
    });
  }

  disposal(rNo: string, sku: string, qty: number, grade: string) {
    console.log(rNo, sku, qty as number, grade);

    if (qty == 0) {
      alert('Qty is 0.');
    }
    else if (qty > 0) {
      this.shippingService.setDisposal(this.mainForm.get('customer').value, rNo, sku, qty, grade).subscribe(response => {
        if (response['isSuccess'] == true) {
          alert('item disposed');
          this.onSubmit();
        } else {
          alert('could not dispose because ' + response['errorMsg']);
          this.onSubmit();
        }
      });
    }
  }

  unholdRR2(rNo: string, sku: string, qty: number, grade: string) {
    if (qty == 0) {
      alert('Qty is 0.');
    }
    else if (qty > 0) {
      this.shippingService.unholdRR2(this.mainForm.get('customer').value, rNo, sku, qty, grade).subscribe(response => {
        if (response['isSuccess'] == true) {
          alert('The item is successfully restocked.');
          this.onSubmit();
        } else {
          alert(`Found some error(s). Please check erorr(s) below: \r\n\r\n${response['errorMsg']}`);
          this.onSubmit();
        }
      });
    }
  }


  memoInit() {
    this.memoForm = this.fb.group({
      customer: this.currentUser.customer,
      receivedNo: '',
      content: ''
    });
  }

  onSubmit() {
    console.log(this.mainForm.value);
    this.isLoading = true;
    this.shippingService.getRRList(this.mainForm.value).subscribe(data => {
      console.log(data);
      this.isLoading = false;
      this.elements = data;
    });
  }

  onMemoSubmit(customerData) {
    console.warn(customerData);
    console.log(this.memoForm.value);

    if (this.memoForm.valid) {
      this.shippingService.returnMemo(this.memoForm.value).subscribe(data => {

        if (data["isSuccess"]) {
          alert('successfully updated.');
          this.memoInit();
          this.onSubmit();
        }
        else {
          alert(data["errorMsg"]);
        }
      });
    }
    else {
      alert(this.memoForm.status);
    }
  }

  unholdRR(rNo: string) {
    this.shippingService.unholdRR(this.mainForm.get('customer').value, rNo).subscribe(response => {
      alert('successfully restock.');

      this.onSubmit();
    });
  }

  

  



 

  gotoReorder(rrno, no) {
    this.router.navigate(['/reorderinit', rrno, no]);
  }

  gotoRRCreation() {
    this.router.navigate(['/return']);
  }
}
