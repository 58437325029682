import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '@app/_models';
import { UserService, AuthenticationService, ShippingService } from '@app/_services';
import * as XLSX from 'xlsx';
import { IMyOptions } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-restocked-list',
  templateUrl: './restocked-list.component.html',
  styleUrls: ['./restocked-list.component.scss']
})

export class RestockedListComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ReturnList.xlsx');
  }

  public myDatePickerOptions: IMyOptions = {
    firstDayOfWeek: 'su' // 'mo' - Monday, 'tu' - Tuesday, 'we' - Wednesday, 'th' - Thursday, 'fr' - Friday, 'sa' - Saturday, 'su' - Sunday
  };

  currentUser:User;

  mainForm;
  memoForm;

  elements: any;
  total: number;
  locations: string[] = ["All", "AWISEC", "AWICAS"];
  isLoading: boolean = false;

  constructor(private fb: FormBuilder, 
    private http: HttpClient,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private shippingService: ShippingService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.userValue;

    var startAt = new Date(new Date().setHours(0, 0, 0, 0))
    startAt.setMonth(startAt.getMonth() - 1);

    this.activatedRoute.paramMap.subscribe(params => {
      this.mainForm = this.fb.group({
        customer: this.currentUser.customer,
        location: params.get('location') ? params.get('location') : 'All',
        startAt: startAt,
        endAt: new Date(new Date().setHours(0, 0, 0, 0))
      });
      this.onSubmit();
    });
  }


  onSubmit() {
    console.log(this.mainForm.value);
    this.isLoading = true;
    this.shippingService.getRestockList(this.mainForm.value).subscribe(data => {
      console.log(data);
      //this.total = data.length; // type 변환 필요
      this.isLoading = false;
      this.elements = data;
    });
  }

  //onMemoSubmit(customerData) {
  //  console.warn(customerData);

  //  if (this.memoForm.valid) {
  //    this.shippingService.returnMemo(this.memoForm.value).subscribe(data => {
  //      //console.log(data);

  //      if (data["isSuccess"]) {
  //        this.memoInit();
  //        this.onSubmit();
  //      }
  //      else {
  //        alert(data["errorMsg"]);
  //      }
  //    });
  //  }
  //  else {
  //    alert(this.memoForm.status);
  //  }
  //}

  //unholdRR(rNo: string) {
  //  this.shippingService.unholdRR(this.mainForm.get('customer').value, rNo).subscribe(response => {
  //    alert('successfully unheld.');

  //    this.onSubmit();
  //  });
  //}

  //delete(rNo: string) {
  //  console.log(this.mainForm.get('customer').value, rNo);
  //  this.returnService.deleteRR(this.mainForm.get('customer').value, rNo).subscribe(response => {
  //    if (response["isSuccess"] == true) {
  //      alert(response["errorMsg"]);
  //    } else {
  //      alert(response["errorMsg"]);
  //    }
  //  });
  //}

  gotoRRCreation() {
    this.router.navigate(['/restorcked-list']);
  }
}
