import { Component, HostListener, ViewChild, OnInit, Input, ElementRef, EventEmitter  } from '@angular/core';
import { first } from 'rxjs/operators';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray, ValidationErrors, AbstractControl,NgModel,NgControl } from '@angular/forms';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { delay, switchMap, map, tap } from 'rxjs/operators';
import { Time, Location } from '@angular/common';
import { Router } from '@angular/router';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { ShippingService } from '@app/_services';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Shipping, Label, SimpleLabel } from '../Class';

@Component({
  selector: 'app-orderdetai',
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.scss']
})

export class OrderDetailComponent {

  isTest: boolean = true;
  locations: string[] = ["AWISEC", "AWICAS"]

  currentUser:User;
  orderdetail: any;
  orderNo;
  customerNo;
  label: any;
  isReorder: boolean = false;
  itemlist: ItemList[];

  isTrackingEditable: boolean = false;
  isAddressEditable: boolean = false;
  isItemEditable: boolean = false;

  customer;
  shippingForm: any;
  mainForm;
  
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router, private location: Location,
    private shippingService: ShippingService,
    private _ActivatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.userValue;
    this.mainForm = this.fb.group({
      customer: this.currentUser.customer,
      orderNo: '',
      customerNo: ''
    });
    this.customer = this.mainForm.controls['customer'];
    this.orderNo = this.mainForm.controls['orderNo'];
    this.customerNo = this.mainForm.controls['customerNo'];

  }

  sub;
  no;

  ngOnInit() {
    this.sub = this._ActivatedRoute.paramMap.subscribe(params => {
      this.no = params.get('no');
      this.shippingService.orderDetail2(this.currentUser.customer, this.no)
        .subscribe(data => {
       this.orderdetail = data;
        console.log(this.orderdetail);
      });
    })

  }

  onSubmit() {
    console.log(typeof this.orderNo.value);
    console.log(typeof this.customerNo.value);
    if (this.orderNo.value != '') {
      this.location.replaceState(`/orderdetail/${this.orderNo.value}`);
      this.shippingService.orderDetail2(this.customer.value, this.orderNo.value)
        .subscribe(data => {
          this.orderdetail = data;
          console.log(this.orderdetail);
        });
    } else {
      this.shippingService.postOrderDetail(0, this.customerNo.value)
        .subscribe(data => {
          this.orderdetail = data;
          console.log(this.orderdetail);
          this.no = this.orderdetail.orderNo;
          this.location.replaceState(`/orderdetail/${this.orderdetail.orderNo}`);
        })
    }
  }

  addressUpdateCancel() {
    this.isAddressEditable = !this.isAddressEditable;
  }

  addressUpdate() {
    this.shippingService.updateAddress(this.orderdetail).subscribe(data => {
      console.log(data);
      alert('Successfully updated.');
    })
    this.isAddressEditable = !this.isAddressEditable;
  }

  updateAddressForm(): void {
    if (this.orderdetail.labels != null) {
      if (this.orderdetail.labels[0].trackingNo != null) {
        alert('Fail to update the address because the label was already created.');
        return;
      }
    }
    if (this.orderdetail.type == "Spf") {
      alert('Fail to update the address because this type is Spf.');
      return;
    }
    this.isAddressEditable = !this.isAddressEditable;
  }

  cancelOrder() {
    if (this.orderdetail.type == 'Spf' || this.orderdetail.type == 'Jet') {
      alert('Cannot cancel: This order type is Spf or Jet.');
    }
    else if (this.orderdetail.labels != null && this.orderdetail.labels[0].trackingNo != null) {
      alert('Cannot cancel: The label has been created.');
    }
    //else if (this.orderdetail.packingEndAt != null) {
    //  alert('Cannot cancel: The items have been packed.');
    //}
    else {
      this.shippingService.cancelOrder(this.orderdetail).subscribe(data => {
        alert('The order is canceled.');
      });
    }
  }

  holdShipping() {
    console.log(this.no);
    this.shippingService.hold(Number(this.no)).subscribe(data => {
      if (data['isSuccess'] == true) {
        this.shippingService.orderDetail2(this.currentUser.customer, this.no)
          .subscribe(data => {
            this.orderdetail = data;
          });
        alert('Hold done.');

      } else {
        alert(data['errorMsg']);
      }
    })
  }

  unholdShipping() {
    console.log(this.no);
    this.shippingService.unhold(Number(this.no)).subscribe(data => {
      this.shippingService.orderDetail2(this.currentUser.customer, this.no)
        .subscribe(data => {
          this.orderdetail = data;
        });
      alert('Unhold done.');
    })
  }

  createReorder() {
    console.log(this.isReorder);
    this.isReorder = !this.isReorder;
    console.log(this.isReorder);
    this.shippingForm = this.fb.group({

      curtomer: "Krave",
      orderno: this.orderdetail.orderNo + 30000000,
      parentorderno: this.orderdetail.orderNo,
      location: this.orderdetail.location,

      items: this.loadItems(),

      shipTo: this.fb.group({
        name: this.orderdetail.shipTo.name,
        company: this.orderdetail.shipTo.company,
        email: this.orderdetail.shipTo.email,
        address1: this.orderdetail.shipTo.address1,
        address2: this.orderdetail.shipTo.address2,
        city: this.orderdetail.shipTo.city,
        state: this.orderdetail.shipTo.state,
        zip: this.orderdetail.shipTo.zip,
        country: 'US',
        phone: this.orderdetail.phone,
      }),

      labels: this.loadLabels(),
      //this.fb.array([
      //  this.fb.group({
      //    IsSignature: this.orderdetail.labels[0].isSignature,
      //    IsCertified: this.orderdetail.labels[0].isCertified,
      //    Carrier: this.orderdetail.labels[0].carrier,
      //    ShippingOption: this.orderdetail.labels[0].shippingOption
      //  })
      //]),
    });

  //  this.shippingForm.get('items').setValue(this.orderdetail.items);
    console.log(this.shippingForm.value);
    console.log(this.orderdetail);
  }

  
  addsku(sku: string, qty: number): FormGroup {
    let n = this.itemlist.findIndex(j => j.sku == sku);
    return this.fb.group({
      sku: [sku],
      desc: [this.itemlist[n].desc],
      qty: [qty]
    });
  }

  loadItems(): FormArray {
    let fa = new FormArray([]);
    for (let i = 0; i < this.orderdetail.items.length; i++) {
      fa.push(this.fb.group(new Item(this.orderdetail.items[i].sku,
                                    this.orderdetail.items[i].desc,
                                    this.orderdetail.items[i].qty,
                                    this.orderdetail.items[i].fulfillableQty)));
    }
    return fa;
  }

  loadLabels(): FormArray {
    let fa = new FormArray([]);
    if (this.orderdetail.labels == null || this.orderdetail.labels[0] == null) {
      fa.push(this.fb.group({
        IsSignature: [Boolean],
        IsCertified: [Boolean],
        Carrier: [''],
        ShippingOption: ['']
      }))
    } else {
      fa.push(this.fb.group({
        IsSignature: this.orderdetail.labels[0].isSignature,
        IsCertified: this.orderdetail.labels[0].isCertified,
        Carrier: this.orderdetail.labels[0].carrier,
        ShippingOption: this.orderdetail.labels[0].shippingOption
      }))
    }
    return fa;
  }

  updateItemsForm() {
    if (this.orderdetail.type == "Spf") {
      alert('You cannot modify this order because this order type is Shopify Order.');
      return;
    }

    else if (this.orderdetail.labels != null && this.orderdetail.labels[0].trackingNo != null) {
      alert('You cannot modify this order because a label was already created.');
      return;
    }

    else {
      this.isItemEditable = !this.isItemEditable;
      this.shippingService.getItemList(this.orderdetail.customer)
        .subscribe(data => {
          this.itemlist = data as ItemList[];
          console.log(this.itemlist);
        });
    }
  }

  addItem() {
    this.orderdetail.items.push(new Item(null,null,null,0));
  }

  deleteItem(index) {
    this.orderdetail.items.splice(index, 1);
  }

  updateItems() {
    this.isItemEditable = !this.isItemEditable;
    console.log(this.orderdetail);
    this.shippingService.updateItems(this.orderdetail)
      .subscribe(data => {
        this.shippingService.orderDetail2(this.currentUser.customer, this.no)
          .subscribe(data => {
            this.orderdetail = data;
            console.log(this.orderdetail);
          });
        alert('successfully updated');
       
      });
  }

  selectSku(index: number, sku) {
    var i = this.itemlist.findIndex(i => i.sku == sku);
    this.orderdetail.items[index].desc = this.itemlist[i].desc;
    if (!this.orderdetail.items[index].qty) this.orderdetail.items[index].qty = 1;
  }

  selectDesc(index: number, desc) {
    var i = this.itemlist.findIndex(i => i.desc == desc);
    this.orderdetail.items[index].sku = this.itemlist[i].sku;
    if (!this.orderdetail.items[index].qty) this.orderdetail.items[index].qty = 1;
  }




  completeitesm() {
    for (let i = 0; i < this.orderdetail.items.length; i++) {
      this.addsku(this.orderdetail.items[i].sku, this.orderdetail.items[i].qty);
      console.log(this.orderdetail.items[i].sku);
      console.log(this.orderdetail.items[i].qty);
      this.add();
    }
  }

  submit(formvalue: object) {
  
    console.log(formvalue);
    this.shippingService.orderCreation(formvalue)
      .subscribe(data => {
        console.log(data);
        alert('successfully created');
        //this.ngOnInit();
      });

  }

  //get items(): FormArray {
  //  return this.shippingForm.get('items') as FormArray;
  //}

  //completeitems() {
  //  for (let i = 0; i < this.orderdetail.items.length; i++) {
  //    this.items.at(i).get('sku').setValue(this.orderdetail.items[i].sku);
  //    //this.shippingForm.items[i].sku = this.orderdetail.items[i].sku;
  //    //this.items[i].desc = this.orderdetail.items[i].desc;
  //    //this.items[i].qty = this.orderdetail.items[i].qty;
  //    //this.items[i].fulfillableQty = this.orderdetail.items[i].qty;
  //    this.add();
  //  }
  //}

  whichCarrier(index: number): string {
    if (this.labels[index].Carrier == 'Endicia') {
      return 'Endicia';
    }
    if (this.labels[index].Carrier == 'FedEx') {
      return 'FedEx';
    }
  }

  get labels(): Array<SimpleLabel> {
    return <Array<SimpleLabel>>this.shippingForm.get('labels').value as Array<SimpleLabel>;
  }

  additem(): FormGroup {
    return this.fb.group({
      sku: ['', Validators.required],
      desc: [''],
      qty: [Number],
      fulfillableQty: [Number]
    })
  }

  addlabel(): FormGroup {
    return this.fb.group({
      IsSignature: [Boolean],
      IsCertified: [Boolean],
      Carrier: String,
      ShippingOption: String
    })
  }

  add(): void {
    (<FormArray>this.shippingForm.get('items')).push(this.additem());
  }

  delete(index): void {
    (<FormArray>this.shippingForm.get('items')).removeAt(index);
    //console.log(this.items);
  }

 

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

class Item {
  sku: string;
  desc: string;
  qty: number;
  fulfillableQty: number;
  constructor(sku, desc, qty, fulfillableQty) {
    this.sku = sku;
    this.desc = desc;
    this.qty = qty;
    this.fulfillableQty = fulfillableQty;
  }
}

class ItemList {
  id: string;
  sku: string;
  upc: string;
  upc2: string;
  desc: string;
  weight: number;
  customer: string;
  isEditable: boolean = false;
}
