<div *ngIf="isLoading">
  <div style="position:absolute;height:100vh; width:100vw; text-align:center;background-color:#fff;opacity:.4;"></div>
  <div style="position: absolute;width: 100vw;text-align: center;top: 40%;"><img src="/assets/images/loader.svg" /></div>
</div>
<div class="container" >
  <div align="right">
    <button (click)="ExportTOExcel()">Excel</button>
    <button (click)="exportPDF()">PDF</button>
  </div>
  <div #TABLE>
    <h3>Inventory (NJ)</h3>

    <table class="table table-bordered table-sm">
      <thead>
        <tr style="text-align: center; font-weight:bold; background-color: lightgray;">
          <td>Sku</td>
          <td>Description</td>
          <td>On Hand</td>
          <td>Available</td>
          <td>Avg Qty / Day</td>
          <td>Days to Cover</td>
          <td>Estimated Stock Out Date</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of inventoryInSEC; let i = index">
          <td>{{el.sku}}</td>
          <td>{{el.description}}</td>
          <td style="text-align:center;">{{el.onHandQty}}</td>
          <td style="text-align:center;">{{el.availableQty}}</td>
          <td style="text-align:center;">{{el.avgSalesQty}}</td>
          <td style="text-align:center;">{{el.daysToCover}}</td>
          <td style="text-align:center;">{{el.expectedStockOut | date:'MM-dd-yy':'GMT'}}</td>
        </tr>
        <tr *ngFor="let box of boxInventory">
          <td>{{box.name}}</td>
          <td></td>
          <td></td>
          <td style="text-align:center;">{{box.onhandQty.awisec}}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <h3>Inventory (CA)</h3>
    <table class="table table-bordered table-sm">
      <thead>
        <tr style="text-align: center; font-weight:bold; background-color: lightgray;">
          <td>Sku</td>
          <td>Description</td>
          <td>On Hand</td>
          <td>Available</td>
          <td>Avg Qty / Day</td>
          <td>Days to Cover</td>
          <td>Estimated Stock Out Date</td>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of inventoryInCAS; let i = index">
          <td>{{el.sku}}</td>
          <td>{{el.description}}</td>
          <td style="text-align:center;">{{el.onHandQty}}</td>
          <td style="text-align:center;">{{el.availableQty}}</td>
          <td style="text-align:center;">{{el.avgSalesQty}}</td>
          <td style="text-align:center;">{{el.daysToCover}}</td>
          <td style="text-align:center;">{{el.expectedStockOut | date:'MM-dd-yy':'GMT'}}</td>
        </tr>
        <tr *ngFor="let box of boxInventory">
          <td>{{box.name}}</td>
          <td></td>
          <td></td>
          <td style="text-align:center;">{{box.onhandQty.awicas}}</td>
          <td></td>
          <td></td>

          <td></td>
        </tr>
      </tbody>
    </table>



  </div>

</div>

 
