import { Component, HostListener, ViewChild, OnInit, Input, ElementRef, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray, ValidationErrors, AbstractControl, NgModel, NgControl } from '@angular/forms';
import { Shipping, Package, ItemQty, Box, Address, SimpleLabel } from '../../Class';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { delay, switchMap, map, tap } from 'rxjs/operators';
import { Time } from '@angular/common';
import { Router } from '@angular/router';
import { User } from '@app/_models';
import { UserService, AuthenticationService } from '@app/_services';
import { ShippingService } from '@app/_services';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-excelorder-list',
  templateUrl: './excelorder.component.html',
  styleUrls: ['./excelorder.component.scss']
})

export class ExcelorderComponent {

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'OrderCreationTemplate.xlsx');
  }

  mainForm: any;
  fileData: File = null;
  customers: string[] = ["Krave", "ToyoLife", "AWI", "Spigen", "HoneyJarret", "Arencia", "CrossPoint", "Beauteers", "Beauteers1", "Jayjun", "PeopleLook", "Xuyoni", "ECOEXLAB", "GAMAGE", "AIONCO", "Looksnmeii"];

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private shippingService: ShippingService) {
    this.mainForm = fb.group({
    //  customer: 'Krave',
      formData: new FormData(),
    });

  }


  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    //this.onSubmit();
  }


  onSubmit() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    //  console.log(this.thecustomer);
    //  console.log(formData);
    this.shippingService.excelordercreation(formData)
      .subscribe(res => {
        console.log(res);
        //res.data.filePath;
        if (res["isSuccess"]) {
          let newShippingDraftList = res['data'].newShippingDraftList;
          alert(`${newShippingDraftList.length} order{${newShippingDraftList.length > 0 ? 's ' : ''} created successfully.`);
        }
        else {
          alert(`${res['errorMsg']}`);
        }
      });
  }
}

 
