import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Shipping } from '../Class';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class ShippingService {
  private userSubject: BehaviorSubject<User>;

  constructor(private http: HttpClient) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  cancelOrder(shipping) {
    var url: string = `${environment.apiUrl}/shipping/CanceledUpdate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(url, shipping, httpOptions);
  }

  excelordercreation(formData) {
    const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/shipping/filetest?customer=${currentUser.customer}`, formData);
  }

  getList() {
    return this.http.get(`${environment.apiUrl}/shipping/getlist`);
  }

  getRestList(formdata: object) {
    return this.http.post(`${environment.apiUrl}/shipping/restlist`, formdata);
  }

  getReorderList(formData) {
    //const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/shipping/ReorderList`, formData);
  }

  getFulfilledList(formdata: object) {
    console.log(formdata);
    var url: string = `${environment.apiUrl}/shipping/fulfilledlist`;
    console.log(location);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post(url, formdata, httpOptions);

  }

  getHoldList(location: string) {
    const currentUser = this.userValue;
    console.log(currentUser.customer);
    return this.http.post(`${environment.apiUrl}/shipping/holdlist`, { customer: currentUser.customer, location: location });
  }

  hold(orderno: number) {
    const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/shipping/hold`, { customer: currentUser.customer, orderNo: orderno });
  }

  unhold(orderno: number) {
    const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/shipping/unhold`, { customer: currentUser.customer, orderNo: orderno });
  }

  holdMultipleOrders(idlist: string[]) {
    const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/shipping/HoldMultipleOrders`, { idList: idlist, Customer: currentUser.customer, Location: null });
  }

  unHold(orderno: number) {
    const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/shipping/unhold`, { customer: currentUser.customer, orderNo: orderno });
  }

  unholdMultipleOrders(idlist: string[]) {
    console.log(idlist);
    const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/shipping/UnHoldMultipleOrders`, { idList: idlist, Customer: currentUser.customer, Location: null });
  }

  updateAddress(shipping) {
    var url: string = `${environment.apiUrl}/shipping/AddressUpdate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(url, shipping, httpOptions);
  }

  getMaxOrderNo() {
    //console.log('call getMaxOrderNo');
    const currentUser = this.userValue;
    return this.http.get(`${environment.apiUrl}/shipping/GetMaxOrderNoFromQuery?customer=` + currentUser.customer);
  }

  getItemList(form) {
    const currentUser = this.userValue;
    console.log('call getitemlist');
    //const httpOptions = {
    //  headers: new HttpHeaders({
    //    'Content-Type': 'application/json',
    //  })
    //};
  //  return this.http.post(`${environment.apiUrl}/item/GetItemList`, form, httpOptions);
    return this.http.get(`${environment.apiUrl}/item/GetlistFromQuery?customer=` + currentUser.customer);

   // return this.http.get(`${environment.apiUrl}/item/GetlistFromQuery?customer=` + currentUser.customer);
  }

  memo(form) {
    return this.http.post(`${environment.apiUrl}/shipping/memo`, form);
  }

  orderDetail(orderno: number) {
    console.log(orderno);
    const currentUser = this.userValue;
    console.log(currentUser.customer);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(`${environment.apiUrl}/shipping/orderdetail`, { Customer: currentUser.customer, OrderNo: orderno }, httpOptions);
  }

  orderCreation(formdata: object) {
    var url: string = `${environment.apiUrl}/shipping/CreateReOrder`;
    return this.http.post(url, formdata);
  }

  orderDetail2(cus: string, no: number) {
    var url: string = `${environment.apiUrl}/shipping/orderdetail3/?cus=${cus}&no=${no}`;
    return this.http.get<Shipping>(url);
  }

  postOrderDetail(no: number, cusno: string) {
    var url: string = `${environment.apiUrl}/shipping/orderdetail`;
    const currentUser = this.userValue;
    console.log(currentUser.customer);
    return this.http.post<Shipping>(url, { customer: currentUser.customer, orderNo: no, customerNo: cusno });
  }

  updateItems(shipping) {
    var url: string = `${environment.apiUrl}/shipping/ItemsUpdate`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<Shipping>(url, shipping, httpOptions);

  }

  //========================================================================================================
  putAttention(cus, rma, sku, grade) {
    var url: string = `${environment.apiUrl}/return/PutAttentionInB2C2`;
    const currentUser = this.userValue;
    return this.http.post(url, { customer: cus, rMANo: rma, sku: sku, grade : grade });
  }

  getDisposal() {
    var url: string = `${environment.apiUrl}/return/GetDisposalList`;
    const currentUser = this.userValue;
    return this.http.post(url, { customer: currentUser.customer});
  }

  setDisposal(cus, rma, sku, qty, grade) {
    var url: string = `${environment.apiUrl}/return/SetDisposal`;
    const currentUser = this.userValue;
    return this.http.post(url, { customer: cus, rMANo: rma, sku: sku, unheldQty: qty, grade: grade  });
  }

  getRRList(formData) {
    var url: string = `${environment.apiUrl}/return/GetRRList`;
    return this.http.post(url, formData);
  }

  getRestockList(formData) {
    var url: string = `${environment.apiUrl}/return/GetRestockedList`;
    return this.http.post(url, formData);
  }

  returnMemo(form) {
    return this.http.post(`${environment.apiUrl}/return/memo`, form);
  }

  unholdRR(cus, rma) {
    var url: string = `${environment.apiUrl}/return/UnHold`;
    return this.http.post(url, { customer: cus, rMANo: rma});
  }

  unholdRR2(cus, rma, sku, qty, grade) {
    var url: string = `${environment.apiUrl}/return/UnHold2`;
    return this.http.post(url, { customer: cus, rMANo: rma, sku: sku, unheldQty: qty, grade: grade });
  }

  

   //========================================================================================================

  getFulfillmentReport(startAt, endAt) {
    const currentUser = this.userValue.customer;
    return this.http.get(`${environment.apiUrl}/home/GetFulfillmentReport/${currentUser}/${startAt}-${endAt}`);
  }

  //=================================================================================

  getInventoryBySku(form) {
    const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/inventory/GetInventoryBySku`, form);
  }

  getInventoryByCustomer(form) {
    const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/inventory/GetInventoryByCustomer`, form);
  }

  getBoxInventory(form) {
    const currentUser = this.userValue;
    return this.http.post(`${environment.apiUrl}/Box/GetBoxInventory`, form);
  }
}
