<div class="container">
  <h3>Order Detail </h3>

  <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">
    <table class="fixed">

      <tr>
        <td>Order # :</td>
        <td>
          <input type="number"
                 style="width: 94px; border-top-style: hidden;
                    border-right-style: hidden;
                    border-left-style: hidden;
                    border-bottom-style: hidden;
                    background-color: #eee;"
                 [formControl]="orderNo"
                 placeholder="Order #" />
        </td>
      </tr>
      <tr>
        <td>Customer # :</td>
        <td>
          <input type="text"
                 style="width: 94px; border-top-style: hidden;
                    border-right-style: hidden;
                    border-left-style: hidden;
                    border-bottom-style: hidden;
                    background-color: #eee;"
                 [formControl]="customerNo"
                 placeholder="Customer #" />
        </td>
        <td>
          <button class="button" type="submit">Submit</button>
        </td>

      </tr>
    </table>
    <div class="row" style="float:right;">
      <button *ngIf="orderdetail" mdbBtn type="button" color="indigo" rounded="true" size="sm" data-toggle="modal" data-target="#basicExample" (click)="holdframe.show()" mdbWavesEffect>
        Hold Order
      </button>

      <div mdbModal #holdframe="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-notify modal-warning" role="document">
          <!--Content-->
          <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
              <p class="heading lead">Hold Order</p>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="holdframe.hide()">
                <span aria-hidden="true" class="white-text">&times;</span>
              </button>
            </div>

            <!--Body-->
            <div class="modal-body">
              <div class="text-center">
                <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                <p>
                  Proceed to hold this order?
                </p>
              </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
              <a type="button" mdbBtn color="warning" class="waves-effect" (click)="holdShipping()" (click)="holdframe.hide()" mdbWavesEffect>
                Yes
              </a>
              <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="holdframe.hide()">Cancel</a>
            </div>
          </div>
          <!--/.Content-->
        </div>
      </div>

      <button *ngIf="orderdetail" mdbBtn type="button" color="unique" rounded="true" size="sm" data-toggle="modal" data-target="#basicExample" (click)="cancelframe.show()" mdbWavesEffect>
        Cancel Order
      </button>

      <div mdbModal #cancelframe="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-notify modal-warning" role="document">
          <!--Content-->
          <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
              <p class="heading lead">Cancel Order</p>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelframe.hide()">
                <span aria-hidden="true" class="white-text">&times;</span>
              </button>
            </div>

            <!--Body-->
            <div class="modal-body">
              <div class="text-center">
                <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                <p>
                  Proceed to cancel this order?
                </p>
              </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
              <a type="button" mdbBtn color="warning" class="waves-effect" (click)="cancelOrder()" (click)="cancelframe.hide()" mdbWavesEffect>
                Yes
              </a>
              <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="cancelframe.hide()">Cancel</a>
            </div>
          </div>
          <!--/.Content-->
        </div>
      </div>

      <button mdbBtn type="button" color="amber" rounded="true" size="sm" mdbWavesEffect [hidden]="!isAddressEditable" data-toggle="modal" data-target="#basicExample" (click)="addressframe.show()" mdbWavesEffect>
        Update
      </button>

      <div mdbModal #addressframe="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-notify modal-warning" role="document">
          <!--Content-->
          <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
              <p class="heading lead">Cancel Order</p>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="addressframe.hide()">
                <span aria-hidden="true" class="white-text">&times;</span>
              </button>
            </div>

            <!--Body-->
            <div class="modal-body">
              <div class="text-center">
                <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                <p>
                  Proceed to update the address?
                </p>
              </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
              <a type="button" mdbBtn color="warning" class="waves-effect" (click)="addressUpdate()" (click)="addressframe.hide()" mdbWavesEffect>
                Yes
              </a>
              <a type="button" mdbBtn color="warning" outline="true" class="waves-effect" mdbWavesEffect (click)="addressUpdateCancel()" (click)="addressframe.hide()">Cancel</a>
            </div>
          </div>
          <!--/.Content-->
        </div>
      </div>

      <button *ngIf="orderdetail && !isAddressEditable" mdbBtn type="button" color="amber" rounded="true" (click)="updateAddressForm()" size="sm" mdbWavesEffect>Address Update</button>

    </div>






    <!--<p align="right">
    <button *ngIf="orderdetail" mdbBtn type="button" color="indigo" rounded="true" (click)="holdShipping()" size="sm" mdbWavesEffect>Hold Order</button>
    <button *ngIf="orderdetail" mdbBtn type="button" color="unique" rounded="true" (click)="cancelOrder()" size="sm" mdbWavesEffect>Cancel Order</button>
    <button *ngIf="orderdetail && !isAddressEditable" mdbBtn type="button" color="amber" rounded="true" (click)="updateAddressForm()" size="sm" mdbWavesEffect>Address Update</button>
    <button mdbBtn type="button" color="amber" rounded="true" (click)="addressUpdate()" size="sm" mdbWavesEffect [hidden]="!isAddressEditable">Update</button>

  </p>-->
  </form>

  <div>
    <table class="table table-sm">
      <tbody *ngIf="orderdetail">
        <tr *ngIf="orderdetail.location">
          <td>Location</td>
          <td>{{orderdetail.location}}</td>
        </tr>
        <tr>
          <td>Customer #</td>
          <td>{{orderdetail.customerNo}}</td>
        </tr>
        <tr>
          <td>Order #</td>
          <td>{{orderdetail.orderNo}}</td>
        </tr>

        <tr>
          <td>Items (qty)</td>
          <td>
            <div *ngFor="let item of orderdetail.items;">
              {{item.sku}} - {{item.desc}} ({{item.qty}})
            </div>
          </td>
        </tr>
        <tr>
          <td>Weight</td>
          <td>{{orderdetail.weight}} (lb)</td>
        </tr>

        <tr>
          <td>Hold</td>
          <td>{{orderdetail.isHold}}</td>
        </tr>
        <tr>
          <td>Tracking #</td>
          <td>
            <span *ngIf="orderdetail.labels" [hidden]="isTrackingEditable">{{orderdetail.labels[0].trackingNo}}</span>
            <input *ngIf="isTrackingEditable" type="text" [(ngModel)]="label.trackingNo">
          </td>
        </tr>


        <tr>
          <td>Name</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.name}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.name">
          </td>

        </tr>
        <tr>
          <td>Company</td>
          <td>{{orderdetail.shipTo.company}}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.email}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.email">
          </td>
        </tr>
        <tr>
          <td>Address 1</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.address1}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.address1">
          </td>
        </tr>
        <tr>
          <td>Address 2</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.address2}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.address2">
          </td>
        </tr>
        <tr>
          <td>City</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.city}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.city">
          </td>
        </tr>
        <tr>
          <td>State</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.state}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.state">
          </td>
        </tr>
        <tr>
          <td>Zip</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.zip}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.zip">
          </td>
        </tr>
        <tr>
          <td>Country</td>
          <td>
            <span *ngIf="orderdetail.shipTo" [hidden]="isAddressEditable">
              {{orderdetail.shipTo.country}}
            </span>
            <input *ngIf="isAddressEditable" type="text" [(ngModel)]="orderdetail.shipTo.country">
          </td>
        </tr>
      </tbody>
    </table>
  </div>



  </div>

